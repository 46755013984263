import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-up-button',
  templateUrl: './sign-up-button.component.html',
  styleUrls: ['./sign-up-button.component.scss'],
})
export class SignUpButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}