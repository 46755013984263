import { Injectable } from '@angular/core';
import { Configuration, OpenAIApi } from 'openai';

@Injectable({
  providedIn: 'root',
})
export class OpenAiService {
  private openai: OpenAIApi;
  private configuration: Configuration;
  //  = new Configuration({
  //   apiKey: 'Your-Key-Here',
  // });

  constructor() {}

  setApiKey(apiKey: string) {
    this.configuration = new Configuration({
      apiKey: apiKey,
    });
    this.openai = new OpenAIApi(this.configuration);
  }

  generateText(prompt: string): Promise<string | undefined> {
    return this.openai
      .createCompletion({
        model: 'gpt-3.5-turbo-0301', // 'text-davinci-003',
        prompt: prompt,
        max_tokens: 2000,
        temperature: 0.2,
      })
      .then((response) => {
        return response.data.choices[0].text;
      })
      .catch((error) => {
        return '';
      });
  }

  generateTextChatGpt40 = (content: string): Promise<string | undefined> => {
    return this.openai
      .createChatCompletion({
        model: 'gpt-4',
        messages: [
          {
            role: 'user',
            content: content,
            name: 'Agent',
          },
        ],
        max_tokens: 2000,
        temperature: 0.2,
      })
      .then((response) => {
        return response.data.choices[0].message.content;
      })
      .catch((error) => {
        throw error;
      });
  };

  generateTextChatGpt35 = (content: string): Promise<string | undefined> => {
    return this.openai
      .createChatCompletion({
        model: 'gpt-3.5-turbo-0301', // 'text-davinci-003',
        messages: [
          {
            role: 'user',
            content: content,
            name: 'Agent',
          },
        ],
        max_tokens: 2000,
        temperature: 0.2,
      })
      .then((response) => {
        console.log('success');
        return response.data.choices[0].message.content;
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
        throw error;
      });
  };
}
