import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, UrlTree } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CurrentUserService } from 'src/app/modules/core/services/current-user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chat-icon',
  templateUrl: './chat-icon.component.html',
  styleUrls: ['./chat-icon.component.scss'],
})
export class ChatIconComponent implements OnInit {
  public chatOpened: boolean;
  public isAuthenticated$: Observable<boolean>;

  constructor(private router: Router, private currentUserService: CurrentUserService) {
    this.isAuthenticated$ = this.currentUserService.isAuthenticated();
  }

  ngOnInit() {
    this.subscribeToRouteChanges();
  }

  private subscribeToRouteChanges() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((val) => {
      this.setChatOpenState();
    });
  }

  toggleChat() {
    if (this.chatOpened) {
      this.router.navigate([{ outlets: { chat: null } }]);
    } else {
      this.router.navigate([{ outlets: { chat: ['chat', 'users'] } }]);
    }

    this.chatOpened = !this.chatOpened;
  }

  setChatOpenState() {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    var isNotificationsOutletActive = tree.root.children['chat'] != null;

    this.chatOpened = isNotificationsOutletActive;
  }
}
