import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './modules/core/services/auth-guard/auth-guard.service';
import { JobInterviewQuestionChallengeDialogComponent } from './modules/core/components/job-interview-question-challenge-dialog/job-interview-question-challenge-dialog.component';
import { DashboardComponent } from './modules/core/components/dashboard/dashboard.component';
import { IndexComponent } from './modules/core/components/index/index.component';
import { IndexBannerComponent } from './modules/core/components/index-banner/index-banner.component';
import { StructurePublicComponent } from './modules/core/components/structure-public/structure-public.component';
import { AiPanelComponent } from './modules/core/components/ai-panel/ai-panel.component';

const appRoutes: Routes = [
  // Lazy loaded Auxiliary outlets
  {
    outlet: 'chat',
    path: 'chat',
    loadChildren: () => import('./modules/chat/chat.module').then((m) => m.ChatModule),
  },
  {
    outlet: 'notifications',
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then((m) => m.NotificationsModule),
  },
  {
    outlet: 'top-banner',
    path: 'index',
    component: IndexBannerComponent,
    data: { layout: 'wide' },
  },
  {
    outlet: 'no-answer',
    path: 'no-answer',
    loadChildren: () =>
      import('./modules/job-interview-questions-public/job-interview-questions-public.module').then(
        (m) => m.JobInterviewQuestionsPublicModule
      ),
  },
  {
    outlet: 'no-answer',
    path: '**',
    children: [],
  },
  // Direct root routes
  {
    path: '',
    component: IndexComponent,
    data: { layout: 'wide' },
  },
  // Static pages
  {
    path: 'contact',
    component: StructurePublicComponent,
    data: { layout: 'wide', structureConstant: 'contact' },
  },
  {
    path: 'ai-panel',
    component: AiPanelComponent,
    data: { layout: 'wide', structureConstant: 'ai-panel' },
  },
  {
    path: 'ux',
    component: StructurePublicComponent,
    data: { layout: 'wide', structureConstant: 'ux' },
  },
  {
    path: 'privacy',
    component: StructurePublicComponent,
    data: { layout: 'wide', structureConstant: 'privacy' },
  },
  {
    path: 'terms',
    component: StructurePublicComponent,
    data: { layout: 'wide', structureConstant: 'terms' },
  },
  // Routes for feature modules
  {
    path: 'cheat-sheets',
    loadChildren: () => import('./modules/cheat-sheets/cheat-sheets.module').then((m) => m.CheatSheetsModule),
    data: { layout: 'wide' },
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
    data: { layout: 'wide' },
  },
  {
    path: 'learningpaths',
    loadChildren: () => import('./modules/learning-paths/learning-paths.module').then((m) => m.LearningPathsModule),
    data: { layout: 'wide' },
  },
  {
    path: 'tests',
    loadChildren: () => import('./modules/tests/tests.module').then((m) => m.TestsModule),
    data: { layout: 'wide' },
  },
  {
    path: 'test-runs',
    loadChildren: () => import('./modules/test-runs/test-runs.module').then((m) => m.TestRunsModule),
    data: { layout: 'wide' },
  },
  {
    path: 'job-interview-questions',
    loadChildren: () =>
      import('./modules/job-interview-questions-public/job-interview-questions-public.module').then(
        (m) => m.JobInterviewQuestionsPublicModule
      ),
    data: { layout: 'wide' },
  },
  {
    path: 'best-practices',
    loadChildren: () =>
      import('./modules/best-practices-public/best-practices-public.module').then((m) => m.BestPracticesPublicModule),
    data: { layout: 'wide' },
  },
  {
    path: 'accounts',
    loadChildren: () => import('./modules/accounts/accounts.module').then((m) => m.AccountsModule),
    data: { layout: 'wide' },
  },
  // Direct routes
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'best-practice-categories',
    loadChildren: () =>
      import('./modules/best-practice-categories/best-practice-categories.module').then((m) => m.BestPracticeCategoriesModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'best-practices-admin',
    loadChildren: () => import('./modules/best-practices/best-practices.module').then((m) => m.BestPracticesModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'challenges',
    loadChildren: () => import('./modules/challenges/challenges.module').then((m) => m.ChallengesModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'comments',
    loadChildren: () => import('./modules/comments/comments.module').then((m) => m.CommentsModule),
    data: { layout: 'wide' },
  },
  {
    path: 'companies',
    loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'job-interview-questions-admin',
    loadChildren: () =>
      import('./modules/job-interview-questions/job-interview-questions.module').then((m) => m.JobInterviewQuestionsModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'job-interview-question-lists',
    loadChildren: () =>
      import('./modules/job-interview-question-lists/job-interview-question-lists.module').then(
        (m) => m.JobInterviewQuestionListsModule
      ),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'lp',
    loadChildren: () => import('./modules/landing-pages/landing-pages.module').then((m) => m.LandingPagesModule),
    data: { layout: 'wide' },
  },
  {
    path: 'learning-journals',
    loadChildren: () => import('./modules/learning-journals/learning-journals.module').then((m) => m.LearningJournalsModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'qotd',
    loadChildren: () => import('./modules/question-of-the-day/question-of-the-day.module').then((m) => m.QuestionOfTheDayModule),
    data: { layout: 'wide' },
  },
  {
    path: 'job-interview-question-categories',
    loadChildren: () =>
      import('./modules/job-interview-question-categories/job-interview-question-categories.module').then(
        (m) => m.JobInterviewQuestionCategoriesModule
      ),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'structures',
    loadChildren: () => import('./modules/structures/structures.module').then((m) => m.StructuresModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'student-profiles',
    loadChildren: () => import('./modules/student-profiles/student-profiles.module').then((m) => m.StudentProfilesModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'tags',
    loadChildren: () => import('./modules/tags/tags.module').then((m) => m.TagsModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },
  {
    path: 'teams',
    loadChildren: () => import('./modules/teams/teams.module').then((m) => m.TeamsModule),
    canActivate: [AuthGuardService],
    data: { layout: 'wide' },
  },

  // Other routes
  {
    path: 'job-interview-question-dialog/:jobInterviewQuestionId',
    component: JobInterviewQuestionChallengeDialogComponent,
    data: {},
  },
  // a
  {
    path: 'a',
    data: { layout: 'wide' },
    children: [
      // Routes for feature modules
    ],
  },

  //{
  //  path: '**',
  //  redirectTo: 'learning-journals/lp'
  //}
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      //      enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
