<div class="container">
  <h1 class="mt-2">AI Panel</h1>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-pills">
          <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#sandbox">Sandbox</a></li>
          <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#timeline">Timeline</a></li>
          <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#settings">Settings</a></li>
        </ul>
      </div>

      <div class="col-md-12 tab-content">
        <div id="sandbox" class="tab-pane fade show active">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="title">Input</label>
                <textarea id="title" class="form-control" formControlName="title" style="height: 400px"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary mr-1" (click)="ask()">Ask AI</button>
                <button class="btn btn-primary mr-1" (click)="onVoiceRecognitionToggle()">{{ buttonText }}</button>
                <button class="btn btn-primary mr-1" (click)="sayHello()">Say it</button>
                <app-loading [isLoading]="askingInProgress"></app-loading>
                <div>
                  {{ counterString }}
                </div>
              </div>
              <div class="form-group">
                <input type="checkbox" [checked]="continuousRecording" (change)="continuousRecording = !continuousRecording" />
                Continuous
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="result">AI Result</label>
                <textarea id="result" class="form-control" formControlName="result" style="height: 500px"></textarea>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="preview">Preview</label>
                <div id="preview" class="form-control" style="height: 500px; overflow: scroll" [innerHTML]="preview"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="timeline" class="tab-pane fade">
          <div formArrayName="timelineItems">
            <div *ngFor="let item of timelineItems.controls; let i = index">
              <div [formGroupName]="i" class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <div class="col-sm-2">
                      <select formControlName="speakerIdentifier">
                        <option value="a">Speaker 1</option>
                        <option value="b">Speaker 2</option>
                        <option value="c">Speaker 3</option>
                      </select>
                    </div>
                    <div class="col-sm-7">
                      <div
                        *ngIf="getTimelineItemFieldValue(i, 'mode') !== 'edit'"
                        (dblclick)="setTimelineItemFieldValue(i, 'mode', 'edit'); something.focus()"
                      >
                        <pre style="border: none; white-space: pre-wrap; background: whitesmoke; border-radius: 5px">{{
                          item.get('message').value
                        }}</pre>
                      </div>
                      <textarea
                        class="form-control"
                        formControlName="message"
                        [style.display]="getTimelineItemFieldValue(i, 'mode') === 'edit' ? 'block' : 'none'"
                        (blur)="setTimelineItemFieldValue(i, 'mode', 'view')"
                        #something
                      ></textarea>
                    </div>
                    <div class="col-sm-1">
                      <button class="btn btn-xs px-1 btn-outline-primary ml-auto" (click)="removeTimelineItem(i)">x</button>
                    </div>
                  </div>
                  <!-- <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Notes:</label>
                    <div class="col-sm-10">
                      <textarea class="form-control" formControlName="notes"></textarea>
                    </div>
                  </div> -->
                  <!-- <hr /> -->
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary mx-3" (click)="addTimelineItem()">Add</button>
        </div>
        <div id="settings" class="tab-pane fade">
          <div class="col-md-12">
            <div class="form-group">
              <label for="open-ai-api-key">API Key</label>
              <input id="open-ai-api-key" type="password" class="form-control" formControlName="openAiApiKey" />
            </div>
            <div class="form-group">
              <select class="custom-select mb-3" (change)="changeChatGptVersion($event)" formControlName="chatGptVersion">
                <option *ngFor="let chatGptVersion of chatGptVersions" [ngValue]="chatGptVersion">
                  {{ chatGptVersion }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
