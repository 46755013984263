<div *ngIf="errors" class="validation-summary">
  <div style="text-align: left;">
    <span class="badge badge-warning p-1">Validation errors</span>
  </div>
  <ul *ngFor="let error of errors | keyvalue" class="py-2">
    <li>
      {{ error.value }}
    </li>
  </ul>
</div>
