import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaDataService } from '../../services/metadata/metadata.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  constructor(private metaDataService: MetaDataService) {}

  ngOnInit() {
    this.metaDataService
      .setPageTitle('Learn Software Development, Build Career, Change The World')
      .addMetaTagDescription(
        "BetterCoder.io is your go-to destination to learn software development and master job interviews. With the world's largest job interview question database, training tools, and challenging tests, you can build your skills and become a successful expert. Get access to free features like browsing and filtering questions, categorization, and community discussions. Stay up-to-date with the latest technologies and improve your knowledge efficiently. Subscribe to our newsletter for the latest news and updates."
      )
      .addOpenGraphType('website');
  }
}
