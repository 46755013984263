import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/api';
import { Router } from '@angular/router';
import { SignInFormModel } from './SignInFormModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from '@api/modules/core/services/current-user.service';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
  providers: [],
})
export class SignInFormComponent implements OnInit {
  form: UntypedFormGroup;
  errors: string;
  model: SignInFormModel = new SignInFormModel();
  submitted: boolean = false;
  passwordMasked: boolean = true;
  passwordBoxType: string = 'password';
  passwordBoxClass: string = 'fa-eye-slash';
  isWorking: boolean;

  constructor(
    private accountsService: AccountsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private currentUserService: CurrentUserService,
    private modalService: NgbModal) {
    }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false],
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.submitted = true;
    this.isWorking = true;
    let signInRequest: SignInFormModel = this.form.value as SignInFormModel;

    this.accountsService
      .signIn(signInRequest, 'response')
      .subscribe(
        (value) => {
          this.modalService.dismissAll();
          this.currentUserService.setIsAuthenticated(true);
          this.router.navigate([value.body.location]);
        },
        (error) => {
          this.errors = error.error;
        }
      )
      .add(() => {
        this.isWorking = false;
      });
  }

  togglePassword() {
    if (this.passwordMasked) {
      this.passwordBoxType = 'text';
      this.passwordBoxClass = 'fa-eye-slash';
    } else {
      this.passwordBoxType = 'password';
      this.passwordBoxClass = 'fa-eye';
    }

    this.passwordMasked = !this.passwordMasked;
  }
}