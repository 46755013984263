/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { QuestionOfTheDay } from '../model/questionOfTheDay';
import { QuestionOfTheDayAnswer } from '../model/questionOfTheDayAnswer';
import { QuestionOfTheDayFeedback } from '../model/questionOfTheDayFeedback';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class QuestionOfTheDayService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Submits an answer for the question of the day
   * @param categoryName Category name/topic that the questions are related to
   * @param body Question of the day answer to be submitted
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addAnswer(
    categoryName: string,
    body: QuestionOfTheDayAnswer,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public addAnswer(
    categoryName: string,
    body: QuestionOfTheDayAnswer,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addAnswer(
    categoryName: string,
    body: QuestionOfTheDayAnswer,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addAnswer(
    categoryName: string,
    body: QuestionOfTheDayAnswer,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (categoryName === null || categoryName === undefined) {
      throw new Error('Required parameter categoryName was null or undefined when calling addAnswer.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addAnswer.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/question-of-the-day/${encodeURIComponent(String(categoryName))}/answers`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Submits an feedback for the question of the day
   * @param categoryName Category name/topic that the questions are related to
   * @param body Question of the day feedback to be submitted
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addFeedback(
    categoryName: string,
    body: QuestionOfTheDayFeedback,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public addFeedback(
    categoryName: string,
    body: QuestionOfTheDayFeedback,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addFeedback(
    categoryName: string,
    body: QuestionOfTheDayFeedback,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addFeedback(
    categoryName: string,
    body: QuestionOfTheDayFeedback,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (categoryName === null || categoryName === undefined) {
      throw new Error('Required parameter categoryName was null or undefined when calling addFeedback.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addFeedback.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/question-of-the-day/${encodeURIComponent(String(categoryName))}/feedback`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a learning journal entry by id
   * @param categoryName Category name/topic that the questions are related to
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuestion(categoryName: string, observe?: 'body', reportProgress?: boolean): Observable<QuestionOfTheDay>;
  public getQuestion(
    categoryName: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<QuestionOfTheDay>>;
  public getQuestion(categoryName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<QuestionOfTheDay>>;
  public getQuestion(categoryName: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (categoryName === null || categoryName === undefined) {
      throw new Error('Required parameter categoryName was null or undefined when calling getQuestion.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<QuestionOfTheDay>(
      `${this.basePath}/question-of-the-day/${encodeURIComponent(String(categoryName))}/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
