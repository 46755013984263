<h3>BetterCoder.io</h3>
<ul>
  <li><a [routerLink]="['/']">Home</a></li>
  <li><a [routerLink]="['/tests']">Tests</a></li>
  <li><a [routerLink]="['/job-interview-questions']">Job Interview Question Browser</a></li>
  <li><a [routerLink]="['/best-practices']">Best Practices</a></li>
</ul>

<h3>Hot topics</h3>
<ul>
  <li>
    <a [routerLink]="['/job-interview-questions/c/39/npm']">npm Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/43/npm-for-beginners']">npm Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/33/sass']">Sass Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/25/sass-for-beginners']">Sass Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/37/angular-cli']">Angular CLI Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/41/angular-cli']">Angular CLI Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/30/angular']">Angular Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/23/angular-for-beginners']">Angular Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/38/typescript']">TypeScript Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/42/typescript-for-beginners']">TypeScript Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/34/fiddler']">Fiddler Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/37/fiddler-for-beginners']">Fiddler Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/35/postman']">Postman Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/38/postman-for-beginners']">Postman Test for Beginners</a>
  </li>
</ul>
