/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BlogEntry } from '../model/blogEntry';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class BlogService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Gets blog entries
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlogEntries(observe?: 'body', reportProgress?: boolean): Observable<Array<BlogEntry>>;
  public getBlogEntries(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BlogEntry>>>;
  public getBlogEntries(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BlogEntry>>>;
  public getBlogEntries(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<BlogEntry>>(`${this.basePath}/blog`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Gets blog selection detail
   * @param jobInterviewQuestionListId Job interview Question List identifier
   * @param seoFriendlyName Job interview Question List Seo Friendly Name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlogSelectionDetail(
    jobInterviewQuestionListId: number,
    seoFriendlyName: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public getBlogSelectionDetail(
    jobInterviewQuestionListId: number,
    seoFriendlyName: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public getBlogSelectionDetail(
    jobInterviewQuestionListId: number,
    seoFriendlyName: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public getBlogSelectionDetail(
    jobInterviewQuestionListId: number,
    seoFriendlyName: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionListId === null || jobInterviewQuestionListId === undefined) {
      throw new Error('Required parameter jobInterviewQuestionListId was null or undefined when calling getBlogSelectionDetail.');
    }
    if (seoFriendlyName === null || seoFriendlyName === undefined) {
      throw new Error('Required parameter seoFriendlyName was null or undefined when calling getBlogSelectionDetail.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<any>(
      `${this.basePath}/blog/${encodeURIComponent(String(jobInterviewQuestionListId))}/${encodeURIComponent(
        String(seoFriendlyName)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
