import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationCenterService, Notification } from 'src/app/api';
import { SignalRService } from '../signal-r.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterUiService {
  constructor(private notificationCenterService: NotificationCenterService,
    private signalRService: SignalRService) {
  }

  public getNewNotificationEvent(): Observable<Notification> {
    return this.signalRService.getNewNotificationEvent();
  }

  public getNewNotificationCount(): Observable<any> {
    return this.notificationCenterService.getNewNotificationCount();
  }

  public resetNewNotificationCount(): Observable<any> {
    return this.notificationCenterService.resetNewNotificationCount();
  }

  public getNotifications(): Observable<any> {
    return this.notificationCenterService.getNotifications();
  }

  public markNotificationAsRead(notificationId: number): Observable<any> {
    return this.notificationCenterService.markNotificationAsRead(notificationId);
  }
}
