import { Injectable } from '@angular/core';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { from, Observable, Subject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { NotificationService } from '../../notification/Notification.service';
import { AudioService } from './audio/audio.service';
import { Notification, Challenge } from 'src/app/api';
import { CurrentUserService } from './current-user.service';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  public users: any = [];
  public userId: number;
  public isConnected: boolean;

  private hubConnection: HubConnection;
  private newNotificationEventSubject: Subject<Notification> = new Subject<Notification>();
  private challengeUpdatedEventSubject: Subject<Challenge> = new Subject<Challenge>();

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private notificationService: NotificationService,
    private audioService: AudioService
  ) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let isSignalREnabled = false;
        // TODO: Check observable isAuthenticated(), looks like a bug as it's not awaited.

        if (isSignalREnabled && !this.isConnected) {
          this.currentUserService
            .isAuthenticated()
            .pipe(take(1))
            .subscribe((isAuthenticated) => {
              if (isAuthenticated) {
                this.startConnection();
                this.onNotificationMessageReceived((message) => {
                  if (message.type == 1) {
                    this.audioService.playNotification();
                    this.notificationService.success(message.text, false);
                  } else if (message.type == 3) {
                    this.notificationService.error(message.text);
                  }
                });
              }
            });
        }
      }
    });
  }

  public startConnection = () => {
    if (this.isConnected) {
      return;
    }

    var baseUrl = environment.baseApiUrl.replace('/api', '');
    var signalRUrl = baseUrl + '/chathub';

    this.hubConnection = new HubConnectionBuilder().withUrl(signalRUrl).withAutomaticReconnect().build();

    this.registerNewNotificationEventConsumer();
    this.registerChallengeUpdatedEventConsumer();

    this.hubConnection.onclose(() => {
      this.isConnected = false;
    });

    this.hubConnection.onreconnected(() => {
      this.onConnected();
    });

    this.hubConnection
      .start()
      .then(() => {
        this.onConnected();
      })
      .catch((err) => {
        this.isConnected = false;
      });
  };

  public loadConnectedUsers(): Observable<any> {
    var result = from(this.hubConnection.invoke('getConnectedUsers'));

    result.subscribe(
      (connectedUsers) => {
        while (this.users.length > 0) {
          this.users.pop();
        }
        this.users.push(...connectedUsers);
      },
      () => {}
    );

    return result;
  }

  public createChatRoomForOneToOneChatWithRecipient(recipientId: number): Observable<any> {
    return from(this.hubConnection.invoke('createChatRoomForOneToOneChatWithRecipient', recipientId));
  }

  public getChatRoomDetails(chatRoomUniqueIdentifier: string): Observable<any> {
    return from(this.hubConnection.invoke('getChatRoomDetails', chatRoomUniqueIdentifier));
  }

  onConnected() {
    this.isConnected = true;
    this.loadConnectedUsers();
  }

  public sendPrivateChatMessage(chatRoomUniqueIdentifier: string, text: string) {
    this.hubConnection.send('sendPrivateChatMessage', chatRoomUniqueIdentifier, text);
  }

  public onChatMessageReceived(handler) {
    this.hubConnection.on('receiveChatMessage', handler);
  }

  public onNotificationMessageReceived(handler) {
    this.hubConnection.on('receiveNotificationMessage', handler);
  }

  // NewNotificationEvent
  private registerNewNotificationEventConsumer() {
    this.hubConnection.on('newNotificationEvent', (notification) => {
      this.newNotificationEventSubject.next(notification);
    });
  }

  public getNewNotificationEvent(): Observable<Notification> {
    return this.newNotificationEventSubject.asObservable();
  }

  //ChallengeUpdatedEventSubject
  private registerChallengeUpdatedEventConsumer() {
    this.hubConnection.on('challengeUpdatedEvent', (challenge) => {
      this.challengeUpdatedEventSubject.next(challenge);
    });
  }

  public getChallengeUpdatedEvent(): Observable<Challenge> {
    return this.challengeUpdatedEventSubject.asObservable();
  }

  //
  public onNewNotificationEventReceived(handler) {
    this.hubConnection.on('newNotificationEvent', handler);
  }

  public onUserConnected(handler) {
    this.hubConnection.on('userConnected', handler);
  }

  public onUserDisconnected(handler) {
    this.hubConnection.on('userDisconnected', handler);
  }
}
