import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { CurrentUserService } from '../../services/current-user.service';
import { Observable } from 'rxjs';
import { NotificationCenterUiService } from '../../services/notification-center-ui/notification-center-ui.service';

@Component({
  selector: 'app-notifications-icon',
  templateUrl: './notifications-icon.component.html',
  styleUrls: ['./notifications-icon.component.scss'],
})
export class NotificationsIconComponent implements OnInit {
  public newNotificationCount: number;
  public notificationsOpened: boolean;
  public breathingActive: boolean = false;
  public isAuthenticated$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentUserService: CurrentUserService,
    private notificationCenterUiService: NotificationCenterUiService
  ) {
  }

  ngOnInit() {
    this.isAuthenticated$ = this.currentUserService.isAuthenticated();
    this.isAuthenticated$.subscribe(isAuthenticated => {
      if (!isAuthenticated) return;

      this.setNotificationsOpenState();
      this.getNewNotificationCount();
      });


    this.notificationCenterUiService.getNewNotificationEvent().subscribe(
      notification => {
        this.breathingActive = true;
        setTimeout(() => {
          this.breathingActive = false;
        }, 1000);
        this.newNotificationCount++;
      },
      () => {}
    );

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(val => {
      this.setNotificationsOpenState();
    });
  }

  getNewNotificationCount() {
    this.notificationCenterUiService.getNewNotificationCount().subscribe(
      newNotificationCount => {
        this.newNotificationCount = newNotificationCount;
      },
      () => {}
    );
  }

  toggleNotifications() {
    if (this.notificationsOpened) {
      this.router.navigate([{ outlets: { notifications: null } }]);
    } else {
      this.router.navigate([{ outlets: { notifications: ['notifications', 'notification-list'] } }]);
      if (this.newNotificationCount > 0) {
        this.resetNewNotificationCount();
      }
    }

    this.notificationsOpened = !this.notificationsOpened;
  }

  setNotificationsOpenState() {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    var isNotificationsOutletActive = tree.root.children['notifications'] != null;

    this.notificationsOpened = isNotificationsOutletActive;
  }

  resetNewNotificationCount() {
    this.newNotificationCount = 0;
    this.notificationCenterUiService.resetNewNotificationCount().subscribe(
      () => {
      },
      () => {
      }
    );
  }
}
