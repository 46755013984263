import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { CurrentUserService } from '../services/current-user.service';
import { LoggingService } from '../services/logging/LoggingService';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective implements OnInit {
  @Input() appHasRole: string;

  private isVisible = false;

  constructor(
    private currentUserService: CurrentUserService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.applyPermission();
  }

  private applyPermission(): void {
    let isAuthenticated$ = this.currentUserService.isAuthenticated$;
    let userProfile$ = this.currentUserService.getUser();

    isAuthenticated$.pipe(take(1)).subscribe((isAuthenticated) => {
      if (!isAuthenticated) return;

      userProfile$
        .pipe(
          filter((userProfile) => userProfile != null),
          take(1)
        )
        .subscribe(
          (userProfile) => {
            var isAuthorized = userProfile.userRoles.includes(this.appHasRole);

            if (isAuthorized) {
              if (!this.isVisible) {
                this.isVisible = true;
                this.viewContainerRef.createEmbeddedView(this.templateRef);
              }
            } else {
              this.isVisible = false;
              this.viewContainerRef.clear();
            }
          },
          (error) => this.loggingService.error(error)
        );
    });
  }
}
