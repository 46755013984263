<!-- <app-menu-banner2 *ngIf="showFirstBanner"></app-menu-banner2>
<app-menu-banner1 *ngIf="!showFirstBanner"></app-menu-banner1> -->
<nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-deep-dark d-flex justify-content-start m-0 p-0">
  <a [routerLink]="['/']" class="navbar-brand ml-2">BetterCoder.io</a>
  <button
    type="button"
    [attr.aria-expanded]="!isCollapsed"
    aria-label="Main menu"
    class="navbar-toggler collapsed ml-1 mr-2 order-first"
    [class.collapsed]="isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <div id="menu-toggle">
      <div id="hamburger">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="cross">
        <span></span>
        <span></span>
      </div>
    </div>
  </button>
  <div class="collapse navbar-collapse bg-deep-dark" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" [class.active]="isIndexActive">
        <a class="nav-link" [routerLink]="['/']">
          <i class="fas fa-home mr-2"></i>
          Home
        </a>
      </li>
      <li class="nav-item" [class.active]="isTestsActive">
        <a class="nav-link" [routerLink]="['/tests']">
          <i class="fas fa-magic mr-2"></i>
          Tests
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/job-interview-questions']">
          <i class="fas fa-database mr-2"></i>
          Questions
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/best-practices']">
          <i class="fas fa-check-circle mr-2"></i>
          Best Practices
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/lp/learning-journals']">
          <i class="fas fa-book mr-2"></i>
          Learning Journals
        </a>
      </li>
      <!-- <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/learning-journals']">
          <i class="fas fa-book mr-2"></i>
          Learning Journals
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/dev-site-map']">Dev Site Map</a>
      </li> -->
    </ul>
    <app-menu-login-partial></app-menu-login-partial>
  </div>
  <div class="ml-auto position-relative d-flex align-items-stretch py-0">
    <app-chat-icon></app-chat-icon>
    <app-notifications-icon></app-notifications-icon>
  </div>
</nav>
<app-toastr-notification></app-toastr-notification>
<app-sign-in-dialog></app-sign-in-dialog>
