<form id="form-log-in" name="form-log-in" method="post" (ngSubmit)="onSubmit()" #ngForm="ngForm" [formGroup]="form">
  <div class="row">
    <div class="col">
      <div class="mx-auto text-center" style="max-width: 500px">
        <p class="text-center">Sign in using your Better Coder account</p>
        <app-validation-summary [errors]="errors"></app-validation-summary>

        <div class="my-4 px-2">
          <div class="rounded text-center p-3 bg-primary">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label for="userName" class="input-group-text" style="width: 120px">
                    <i class="far fa-envelope mr-2"></i>
                    E-mail
                  </label>
                </div>
                <input id="userName" name="userName" type="text" class="form-control" formControlName="userName" />
              </div>
            </div>

            <div class="form-group">
              <div class="input-group" id="password-box-modal">
                <div class="input-group-prepend">
                  <label for="password" class="input-group-text" style="width: 120px">
                    <i class="fas fa-unlock-alt mr-2"></i>
                    Password
                  </label>
                </div>
                <input
                  type="{{ passwordBoxType }}"
                  id="password"
                  name="password"
                  class="form-control"
                  autocomplete="off"
                  formControlName="password"
                />
                <div class="input-group-append">
                  <span class="input-group-text" id="inputGroup-sizing-default">
                    <a class="text-dark" (click)="togglePassword()">
                      <i class="fas {{ passwordBoxClass }}"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group m-0">
              <div class="checkbox">
                <label for="rememberMe" class="text-white">
                  <input type="checkbox" id="rememberMe" name="rememberMe" formControlName="rememberMe" />
                  Stay signed in
                </label>
              </div>
            </div>

            <button type="submit" class="btn btn-success has-spinner mt-2 font-weight-bold" [disabled]="isWorking">
              <i class="fas fa-sign-in-alt mr-2"></i>
              Sign In
              <i class="fas fa-spinner fa-pulse" *ngIf="isWorking"></i>
            </button>

            <div class="mt-3 text-center text-white">
              <!--<div>
    <a class="text-white">Forgot the password.</a>
  </div>-->
              <div>
                <a class="btn btn-outline-primary text-white" data-dismiss="modal" [routerLink]="['/accounts/sign-up']">
                  Register as a new user
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="row">
  <div class="col-md-6 offset-md-3 text-center">
    <form action="/server/external-login" method="post" class="form-horizontal" ngNoForm>
      <p class="mb-2">or use</p>
      <div>
        <button
          type="submit"
          class="btn btn-primary btn-block mb-1"
          style="background: #4267b2"
          title="Sign in using your Facebook account"
          name="provider"
          value="Facebook"
        >
          <i class="fab fa-facebook mr-1"></i>
          Facebook account
        </button>

        <button
          type="submit"
          class="btn btn-primary btn-block mb-1"
          style="background: #4285f4"
          title="Sign in using your Google account"
          name="provider"
          value="Google"
        >
          <i class="fab fa-google mr-1"></i>
          Google account
        </button>
        <button
          type="submit"
          class="btn btn-primary btn-block mb-1"
          style="background: #00a4ef"
          title="Sign in using your Microsoft account"
          name="provider"
          value="Microsoft"
        >
          <i class="fab fa-microsoft mr-1"></i>
          Microsoft account
        </button>
      </div>
    </form>
  </div>
</div>
