import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { Notification } from './toastr-notification/toastr-notification.model';
import { NotificationType } from './NotificationType';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public subject = new Subject<Notification>();
  public keepAfterRouteChange = true;

  constructor(public router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, autoClose: boolean = true, keepAfterRouteChange = false) {
    this.showNotification(NotificationType.Success, message, autoClose, keepAfterRouteChange);
  }

  error(message: string, autoClose: boolean = true, keepAfterRouteChange = false) {
    this.showNotification(NotificationType.Error, message, autoClose, keepAfterRouteChange);
  }

  info(message: string, autoClose: boolean = true, keepAfterRouteChange = false) {
    this.showNotification(NotificationType.Info, message, autoClose, keepAfterRouteChange);
  }

  warn(message: string, autoClose: boolean = true, keepAfterRouteChange = false) {
    this.showNotification(NotificationType.Warning, message, autoClose, keepAfterRouteChange);
  }

  showNotification(type: NotificationType, message: string, autoClose: boolean, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    var notification = <Notification>{ type: type, message: message, autoClose: autoClose };
    this.subject.next(notification);
  }

  clear() {
    this.subject.next(null);
  }
}
