import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-links1',
  templateUrl: './footer-links1.component.html',
  styleUrls: ['./footer-links1.component.scss']
})
export class FooterLinks1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
