import { Component, OnInit } from '@angular/core';
import { GamepadMapper } from './gamepad/gamepad-mapper';
import { NotificationApiService } from '../../services/notification-api/notification-api.service';
import { AccountsService } from '../../../../api/api/accounts.service';
import { LazyLoadingService } from '../../services/lazy-loading/lazy-loading.service';
//import { WebPushNotificationsService } from '../../services/web-push/web-push.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private lazyLoadingService: LazyLoadingService,
    private accountsService: AccountsService,
    private notificationApiService: NotificationApiService //    private webPushNotificationService: WebPushNotificationsService
  ) {}

  ngOnInit() {
    this.lazyLoadingService.lazyLoadWebNotifications().subscribe({
      next: (a) => {},
      error: (b) => {},
    });

    //    this.connectToGamePad();
    this.accountsService.getUserProfile().subscribe(
      (userProfile) => {},
      (error) => {}
    );
  }

  sendWelcomeNotification() {
    this.notificationApiService.showNotification(
      'Better Coder',
      'You will get notifications like this later when someone challenges you.'
    );
  }

  subscribeToWebPush() {
    //    this.webPushNotificationService.subscribeToPushNotifications();
  }

  private interval: any;

  connectToGamePad() {
    // TODO: Add code to handle the gamepad
    // window.addEventListener('gamepadconnected', (e) => {
    //   if (!('ongamepadconnected' in window)) {
    //     // No gamepad events available, poll instead.
    //     this.interval = setInterval(() => this.pollGamepads(), 10);
    //     let player1 = document.getElementById('player1');
    //     player1.style.display = 'block';
    //     let player2 = document.getElementById('player2');
    //     player2.style.display = 'block';
    //   }
    // });
  }

  private lastControllerUpdate: number = 0;

  pollGamepads() {
    let gamepads = navigator.getGamepads
      ? navigator.getGamepads()
      : (<any>navigator).webkitGetGamepads
      ? (<any>navigator).webkitGetGamepads
      : [];

    let player1 = document.getElementById('player1');
    let player2 = document.getElementById('player2');

    for (var i = 0; i < gamepads.length; i++) {
      let gamePad = gamepads[i];

      if (gamePad) {
        if (gamePad.timestamp <= this.lastControllerUpdate) {
          continue;
        }
        //this.lastControllerUpdate = gamePad.timestamp;

        let speed = 10;

        let gamepadMapper = new GamepadMapper();
        let gamepadState = gamepadMapper.GetState(gamePad);

        if (gamepadState.rStickLeft > 0) {
          let left = parseInt(player1.style.left, 10);
          player1.style.left = left - Math.round(gamepadState.rStickLeft * speed) + 'px';
        }

        if (gamepadState.rStickRight > 0) {
          let left = parseInt(player1.style.left, 10);
          player1.style.left = left + Math.round(gamepadState.rStickRight * speed) + 'px';
        }

        if (gamepadState.rStickTop > 0) {
          let top = parseInt(player1.style.top, 10);
          player1.style.top = top - Math.round(gamepadState.rStickTop * speed) + 'px';
        }

        if (gamepadState.rStickBottom > 0) {
          let top = parseInt(player1.style.top, 10);
          player1.style.top = top + Math.round(gamepadState.rStickBottom * speed) + 'px';
        }

        if (gamepadState.lStickLeft > 0) {
          let left = parseInt(player2.style.left, 10);
          player2.style.left = left - Math.round(gamepadState.lStickLeft * speed) + 'px';
        }

        if (gamepadState.lStickRight > 0) {
          let left = parseInt(player2.style.left, 10);
          player2.style.left = left + Math.round(gamepadState.lStickRight * speed) + 'px';
        }

        if (gamepadState.lStickTop > 0) {
          let top = parseInt(player2.style.top, 10);
          player2.style.top = top - Math.round(gamepadState.lStickTop * speed) + 'px';
        }

        if (gamepadState.lStickBottom > 0) {
          let top = parseInt(player2.style.top, 10);
          player2.style.top = top + Math.round(gamepadState.lStickBottom * speed) + 'px';
        }

        // Right
        if (gamepadState.rB > 0) {
          //          player1.style.background = this.colors[0];
          player1.style.top = parseInt(player1.style.top, 10) + speed + 'px';
        }

        if (gamepadState.rX > 0) {
          //player1.style.background = this.colors[3];
          player1.style.top = parseInt(player1.style.top, 10) - speed + 'px';
        }

        if (gamepadState.rA > 0) {
          //          player1.style.background = this.colors[1];
          player1.style.left = parseInt(player1.style.left, 10) + speed + 'px';
        }
        if (gamepadState.rY > 0) {
          //          player1.style.background = this.colors[2];
          player1.style.left = parseInt(player1.style.left, 10) - speed + 'px';
        }

        if (gamepadState.rSelect > 0) {
          player1.style.top = '0px';
          player1.style.left = '0px';
        }

        if (gamepadState.rStickThummb > 0) {
        }

        if (gamepadState.rHome > 0) {
          player1.style.height = '50px';
          player1.style.width = '50px';
        }

        if (gamepadState.r1 > 0) {
          player1.style.height = parseInt(player1.style.height, 10) + speed + 'px';
        }

        if (gamepadState.r2 > 0) {
          player1.style.height = parseInt(player1.style.height, 10) - speed + 'px';
        }

        if (gamepadState.rSL > 0) {
          player1.style.width = parseInt(player1.style.width, 10) - speed + 'px';
        }

        if (gamepadState.rSR > 0) {
          player1.style.width = parseInt(player1.style.width, 10) + speed + 'px';
        }

        // Left
        if (gamepadState.lArrowDown > 0) {
          player2.style.top = parseInt(player2.style.top, 10) + speed + 'px';
        }

        if (gamepadState.lArrowUp > 0) {
          player2.style.top = parseInt(player2.style.top, 10) - speed + 'px';
        }

        if (gamepadState.lArrowRight > 0) {
          player2.style.left = parseInt(player2.style.left, 10) + speed + 'px';
        }
        if (gamepadState.lArrowLeft > 0) {
          player2.style.left = parseInt(player2.style.left, 10) - speed + 'px';
        }

        if (gamepadState.lCancel > 0) {
          player2.style.left = '0px';
          player2.style.top = '0px';
        }

        if (gamepadState.rStickThummb > 0) {
        }

        if (gamepadState.lBack > 0) {
          player2.style.height = '50px';
          player2.style.width = '50px';
        }

        if (gamepadState.l1 > 0) {
          player2.style.height = parseInt(player2.style.height, 10) + speed + 'px';
        }

        if (gamepadState.l2 > 0) {
          player2.style.height = parseInt(player2.style.height, 10) - speed + 'px';
        }

        if (gamepadState.lSR > 0) {
          player2.style.width = parseInt(player2.style.width, 10) - speed + 'px';
        }

        if (gamepadState.lSL > 0) {
          player2.style.width = parseInt(player2.style.width, 10) + speed + 'px';
        }
      }
    }
  }
}
