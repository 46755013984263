import { Injectable, ErrorHandler, Optional, Inject, InjectionToken } from '@angular/core';

export const ERROR_WRAPPER: InjectionToken<any> = new InjectionToken<any>('ERROR_WRAPPER');

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
  constructor(@Optional() @Inject(ERROR_WRAPPER) private errorWrapper: any) {
    super();
  }

  handleError(error: Error) {
    console.log('Custom Error Handler error: ' + error.message);
    if (this.errorWrapper) {
      //serverSide
      this.errorWrapper.error = error;

      console.log('Error message: ' + error.message);
      console.log('Error name: ' + error.name);

      if (error != null && error.stack != null) {
        console.log('Error stack: ' + error.stack.toString());
      }
    }
  }
}
