<div class="content-block app">
  <h1>Dashboard</h1>
  <div class="d-flex justify-content-end">
    <!-- <button class="btn btn-xs btn-primary mr-2" (click)="sendWelcomeNotification()">Send welcome notification</button>
    <button class="btn btn-xs btn-primary mr-2" (click)="subscribeToWebPush()">Subscribe to Web Push</button> -->
  </div>

  <div id="player1"></div>
  <div id="player2"></div>
  <!--@if (Model.HasInvitationCookie)
  {
  <partial name="_PendingInvitation.cshtml" />
  }
  <partial name="_LearningStats.cshtml" />-->

  <div class="d-flex tiles">
    <a [routerLink]="['/comments/communication-center']" title="Channels" class="i1">
      <i class="fas fa-comments fa-3x" aria-hidden="true"></i>
      Channels
    </a>

    <a [routerLink]="['/challenges']" title="Challenges" class="i8">
      <i class="fas fa-bolt fa-3x" aria-hidden="true"></i>
      Challenges
    </a>

    <a [routerLink]="['/qotd']" title="Question of the day" class="i4">
      <i class="fas fa-certificate fa-3x" aria-hidden="true"></i>
      Question of the day
    </a>

    <a [routerLink]="['/learning-journals']" title="Learning Journals" class="xtile i1">
      <i class="fas fa-book fa-3x" aria-hidden="true"></i>
      Learning Journals
    </a>

    <a [routerLink]="['/tests']" title="Test and Quizzes" class="i3">
      <i class="fas fa-magic fa-3x" aria-hidden="true"></i>
      Tests and Quizzes
    </a>

    <a [routerLink]="['/test-runs']" title="History of your test runs" class="i5">
      <i class="fas fa-history fa-3x" aria-hidden="true"></i>
      Test and Quiz History
    </a>

    <a [routerLink]="['/teams']" title="Your teams" class="i7">
      <i class="fas fa-users fa-3x" aria-hidden="true"></i>
      Teams
    </a>

    <a [routerLink]="['/student-profiles/student-profile/index']" title="Student Profile" class="i4">
      <i class="fas fa-graduation-cap fa-3x" aria-hidden="true"></i>
      Student Profile
    </a>

    <a [routerLink]="['/accounts/user-profile/edit']" title="Manage User Profile" class="i2">
      <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
      Account Settings
    </a>
  </div>

  <app-install-pwa></app-install-pwa>
  <!--<div class="row">
    <div class="col-6 mb-4">
      <a href="/team/index" title="Team" class="xtile text-center">
        <i class="fa fa-users fa-3x" aria-hidden="true"></i>
        Team
      </a>
    </div>
    <div class="col-6 mb-4">
      <a href="/notes" title="Notes" class="xtile text-center">
        <i class="fa fa-users fa-3x" aria-hidden="true"></i>
        Notes
      </a>
    </div>
  </div>-->
  <!--<div class="row">
    <div class="col-6 mb-4 p-2">
      <a href="/OrdersAndServices/Index" title="Orders and Services" class="i3">
        <i class="fa fa-cog fa-3x" aria-hidden="true"></i>
        Orders and Services
      </a>
    </div>
  </div>-->
  <ng-template [appHasRole]="'admin'">
    <h1>Admin</h1>

    <div class="tiles">
      <a [routerLink]="['/job-interview-questions/manager']" title="Job Interview Questions" class="i1">
        <i class="fas fa-stream fa-3x" aria-hidden="true"></i>
        Job Interview Questions
      </a>

      <a [routerLink]="['/best-practices/manager']" title="Best Practices" class="i7">
        <i class="fas fa-stream fa-3x" aria-hidden="true"></i>
        Best Practices
      </a>

      <a [routerLink]="['/student-profiles/list']" title="Student Profiles" class="i4">
        <i class="fas fa-graduation-cap fa-3x" aria-hidden="true"></i>
        Student Profiles
      </a>

      <a [routerLink]="['/companies']" title="Companies" class="i6">
        <i class="fas fa-building fa-3x" aria-hidden="true"></i>
        Companies
      </a>

      <a [routerLink]="['/job-interview-question-categories']" title="Job Interview Question Categories" class="i10">
        <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
        Job Interview Question Categories
      </a>

      <a [routerLink]="['/best-practice-categories']" title="Best Practice Categories" class="i8">
        <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
        Best Practice Categories
      </a>

      <a [routerLink]="['/tags']" title="Tags" class="i5">
        <i class="fa fa-tag fa-3x" aria-hidden="true"></i>
        Tags
      </a>

      <a [routerLink]="['/structures']" title="Structures" class="i1">
        <i class="fas fa-newspaper fa-3x" aria-hidden="true"></i>
        Structures
      </a>

      <a href="/Comment/AllRecent" title="All recent comments" class="i8">
        <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
        All Recent Comments
      </a>

      <a [routerLink]="['/job-interview-question-lists']" title="All lists" class="i9">
        <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
        Job Interview Question Lists
      </a>

      <a href="/Feedbacks/Index" title="All Feedbacks" class="i5">
        <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
        Feedbacks
      </a>

      <a href="/Mailing/ShowInbox" title="Action Items" class="i9">
        <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
        Action Item Inbox
      </a>

      <a href="/question-lists" title="All lists" class="i9">
        <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
        Old Job Interview Question Lists
      </a>
    </div>

    <!--View and manage all action items that you have
    received from or sent to some other team members.-->
    <!--Your Technology Gang
    Build your dream team(s) and leverage the power of collective knowledge.
    Communicate with your team mates and get better together.-->
    <!--Orders and Services
    List of Orders you placed and Services you have available.-->
  </ng-template>
</div>
