import { Component, OnInit } from '@angular/core';
import { PwaService } from '../../services/pwa/pwa.service';
@Component({
  selector: 'app-install-pwa',
  templateUrl: './install-pwa.component.html',
  styleUrls: ['./install-pwa.component.scss'],
})
export class InstallPwaComponent implements OnInit {
  constructor(public pwaService: PwaService) {}

  installPwa(): void {
    this.pwaService.promptEvent.prompt();
  }

  ngOnInit() {}
}
