import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignInService {
  private signInFormOpenEventSubject: Subject<void> = new Subject<void>();

  constructor() { }

  public getSignInFormOpenEvent(): Observable<void> {
    return this.signInFormOpenEventSubject.asObservable();
  }

  public raiseSignInFormOpenEvent() {
    this.signInFormOpenEventSubject.next();
  }

}
