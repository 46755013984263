import { Component, OnInit } from '@angular/core';
import { Notification } from './toastr-notification.model';
import { NotificationService } from '../Notification.service';
import { NotificationType } from '../NotificationType';

@Component({
  selector: 'app-toastr-notification',
  templateUrl: 'toastr-notification.component.html',
  styleUrls: ['./toastr-notification.component.css'],
  host: { '(window:notification-event)': 'onNotificationEvent($event)' },
})
export class NotificationComponent implements OnInit {
  notifications: Notification[] = [];

  constructor(public notificationService: NotificationService) {}

  ngOnInit() {
    this.notificationService.getAlert().subscribe((alert: Notification) => {
      this.pushNotificationInternal(alert);
    });
  }

  onNotificationEvent(event) {
    var alert: Notification = event.detail;
    this.pushNotificationInternal(alert);
  }

  pushNotificationInternal(alert: Notification) {
    this.notifications = [];

    if (!alert) {
      this.notifications = [];
      return;
    }
    console.log(this.notifications);
    this.notifications.push(alert);
    if (alert.autoClose) {
      setTimeout(() => {
        this.notifications = this.notifications.filter((x) => x !== alert);
      }, 4000);
    }
  }

  removeNotification(notification: Notification) {
    this.notifications = this.notifications.filter((x) => x !== notification);
  }

  /**Set css class for Alert -- Called from alert component**/
  cssClass(notification: Notification) {
    if (!notification) {
      return;
    }

    switch (notification.type) {
      case NotificationType.Success:
        return 'toast-success';
      case NotificationType.Error:
        return 'toast-error';
      case NotificationType.Info:
        return 'toast-info';
      case NotificationType.Warning:
        return 'toast-warning';
    }
  }
}
