/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Challenge } from '../model/challenge';
import { CreateAndStartChallengeFromJobInterviewQuestionRequest } from '../model/createAndStartChallengeFromJobInterviewQuestionRequest';
import { CreateAndStartChallengeResponse } from '../model/createAndStartChallengeResponse';
import { ErrorResponse } from '../model/errorResponse';
import { JobInterviewQuestion } from '../model/jobInterviewQuestion';
import { JobInterviewQuestionDetailPage } from '../model/jobInterviewQuestionDetailPage';
import { JobInterviewQuestionStartChallengeResponse } from '../model/jobInterviewQuestionStartChallengeResponse';
import { JobInterviewQuestionsCategoryDetailPage } from '../model/jobInterviewQuestionsCategoryDetailPage';
import { JobInterviewQuestionsIndexPage } from '../model/jobInterviewQuestionsIndexPage';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class JobInterviewQuestionsService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Creates a new job interview question
   * @param body Job interview question object that needs to be added
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addJobInterviewQuestion(body: JobInterviewQuestion, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public addJobInterviewQuestion(
    body: JobInterviewQuestion,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addJobInterviewQuestion(
    body: JobInterviewQuestion,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addJobInterviewQuestion(
    body: JobInterviewQuestion,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addJobInterviewQuestion.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/job-interview-questions`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Creates and starts the job interview question challenge as a response to the previous challenge
   * @param challengeUniqueIdentifier Challenge unique identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAndStartChallengeAsResponseToPreviousChallenge(
    challengeUniqueIdentifier: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CreateAndStartChallengeResponse>;
  public createAndStartChallengeAsResponseToPreviousChallenge(
    challengeUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CreateAndStartChallengeResponse>>;
  public createAndStartChallengeAsResponseToPreviousChallenge(
    challengeUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CreateAndStartChallengeResponse>>;
  public createAndStartChallengeAsResponseToPreviousChallenge(
    challengeUniqueIdentifier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (challengeUniqueIdentifier === null || challengeUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter challengeUniqueIdentifier was null or undefined when calling createAndStartChallengeAsResponseToPreviousChallenge.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.post<CreateAndStartChallengeResponse>(
      `${this.basePath}/job-interview-questions/challenge/${encodeURIComponent(
        String(challengeUniqueIdentifier)
      )}/challenge-back`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Creates and starts the job interview question challenge
   * @param body Job interChallenge unique identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAndStartChallengeFromJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CreateAndStartChallengeResponse>;
  public createAndStartChallengeFromJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CreateAndStartChallengeResponse>>;
  public createAndStartChallengeFromJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CreateAndStartChallengeResponse>>;
  public createAndStartChallengeFromJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createAndStartChallengeFromJobInterviewQuestion.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<CreateAndStartChallengeResponse>(
      `${this.basePath}/job-interview-questions/challenge/job-interview-question`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Creates and starts the job interview question challenge
   * @param body Job interChallenge unique identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAndStartChallengeFromRandomJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CreateAndStartChallengeResponse>;
  public createAndStartChallengeFromRandomJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CreateAndStartChallengeResponse>>;
  public createAndStartChallengeFromRandomJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CreateAndStartChallengeResponse>>;
  public createAndStartChallengeFromRandomJobInterviewQuestion(
    body: CreateAndStartChallengeFromJobInterviewQuestionRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createAndStartChallengeFromRandomJobInterviewQuestion.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<CreateAndStartChallengeResponse>(
      `${this.basePath}/job-interview-questions/challenge/job-interview-question/random`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Creates and starts the job interview question challenge from link
   * @param linkUniqueIdentifier Link unique identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAndStartJobInterviewQuestionChallengeFromLink(
    linkUniqueIdentifier: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CreateAndStartChallengeResponse>;
  public createAndStartJobInterviewQuestionChallengeFromLink(
    linkUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CreateAndStartChallengeResponse>>;
  public createAndStartJobInterviewQuestionChallengeFromLink(
    linkUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CreateAndStartChallengeResponse>>;
  public createAndStartJobInterviewQuestionChallengeFromLink(
    linkUniqueIdentifier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (linkUniqueIdentifier === null || linkUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter linkUniqueIdentifier was null or undefined when calling createAndStartJobInterviewQuestionChallengeFromLink.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.post<CreateAndStartChallengeResponse>(
      `${this.basePath}/job-interview-questions/challenge/link/${encodeURIComponent(String(linkUniqueIdentifier))}`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Deletes a Job interview question
   * @param jobInterviewQuestionId Job interview question Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteJobInterviewQuestion(jobInterviewQuestionId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteJobInterviewQuestion(
    jobInterviewQuestionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteJobInterviewQuestion(
    jobInterviewQuestionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteJobInterviewQuestion(
    jobInterviewQuestionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error('Required parameter jobInterviewQuestionId was null or undefined when calling deleteJobInterviewQuestion.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(
      `${this.basePath}/job-interview-questions/${encodeURIComponent(String(jobInterviewQuestionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a job interview question by id
   * @param jobInterviewQuestionId Job interview question Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getJobInterviewQuestion(
    jobInterviewQuestionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<JobInterviewQuestion>;
  public getJobInterviewQuestion(
    jobInterviewQuestionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<JobInterviewQuestion>>;
  public getJobInterviewQuestion(
    jobInterviewQuestionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<JobInterviewQuestion>>;
  public getJobInterviewQuestion(
    jobInterviewQuestionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error('Required parameter jobInterviewQuestionId was null or undefined when calling getJobInterviewQuestion.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<JobInterviewQuestion>(
      `${this.basePath}/job-interview-questions/${encodeURIComponent(String(jobInterviewQuestionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a job interview question by id with related details for rendering question detail page
   * @param jobInterviewQuestionId Job interview question Identifier
   * @param seoFriendlyName Job interview question name in seo friendly form
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getJobInterviewQuestionDetailPage(
    jobInterviewQuestionId: number,
    seoFriendlyName: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<JobInterviewQuestionDetailPage>;
  public getJobInterviewQuestionDetailPage(
    jobInterviewQuestionId: number,
    seoFriendlyName: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<JobInterviewQuestionDetailPage>>;
  public getJobInterviewQuestionDetailPage(
    jobInterviewQuestionId: number,
    seoFriendlyName: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<JobInterviewQuestionDetailPage>>;
  public getJobInterviewQuestionDetailPage(
    jobInterviewQuestionId: number,
    seoFriendlyName: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error(
        'Required parameter jobInterviewQuestionId was null or undefined when calling getJobInterviewQuestionDetailPage.'
      );
    }
    if (seoFriendlyName === null || seoFriendlyName === undefined) {
      throw new Error('Required parameter seoFriendlyName was null or undefined when calling getJobInterviewQuestionDetailPage.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<JobInterviewQuestionDetailPage>(
      `${this.basePath}/job-interview-questions/${encodeURIComponent(String(jobInterviewQuestionId))}/${encodeURIComponent(
        String(seoFriendlyName)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a list of job interview questions
   * @param keywords
   * @param categoryIds
   * @param jobInterviewQuestionListIds
   * @param tagIds
   * @param jobInterviewQuestionTypeIds
   * @param levels
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getJobInterviewQuestions(
    keywords?: string,
    categoryIds?: Array<number>,
    jobInterviewQuestionListIds?: Array<number>,
    tagIds?: Array<number>,
    jobInterviewQuestionTypeIds?: Array<number>,
    levels?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<JobInterviewQuestion>>;
  public getJobInterviewQuestions(
    keywords?: string,
    categoryIds?: Array<number>,
    jobInterviewQuestionListIds?: Array<number>,
    tagIds?: Array<number>,
    jobInterviewQuestionTypeIds?: Array<number>,
    levels?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<JobInterviewQuestion>>>;
  public getJobInterviewQuestions(
    keywords?: string,
    categoryIds?: Array<number>,
    jobInterviewQuestionListIds?: Array<number>,
    tagIds?: Array<number>,
    jobInterviewQuestionTypeIds?: Array<number>,
    levels?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<JobInterviewQuestion>>>;
  public getJobInterviewQuestions(
    keywords?: string,
    categoryIds?: Array<number>,
    jobInterviewQuestionListIds?: Array<number>,
    tagIds?: Array<number>,
    jobInterviewQuestionTypeIds?: Array<number>,
    levels?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (keywords !== undefined) {
      queryParameters = queryParameters.set('keywords', <any>keywords);
    }
    if (categoryIds) {
      queryParameters = queryParameters.set('categoryIds', categoryIds.join(COLLECTION_FORMATS['csv']));
    }
    if (jobInterviewQuestionListIds) {
      queryParameters = queryParameters.set(
        'jobInterviewQuestionListIds',
        jobInterviewQuestionListIds.join(COLLECTION_FORMATS['csv'])
      );
    }
    if (tagIds) {
      queryParameters = queryParameters.set('tagIds', tagIds.join(COLLECTION_FORMATS['csv']));
    }
    if (jobInterviewQuestionTypeIds) {
      queryParameters = queryParameters.set(
        'jobInterviewQuestionTypeIds',
        jobInterviewQuestionTypeIds.join(COLLECTION_FORMATS['csv'])
      );
    }
    if (levels) {
      queryParameters = queryParameters.set('levels', levels.join(COLLECTION_FORMATS['csv']));
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<JobInterviewQuestion>>(`${this.basePath}/job-interview-questions`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Returns data necessary for rendering job interview questions category detail page
   * @param categoryId Job interview question category identifier
   * @param seoFriendlyName Category SEO friendly name
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getJobInterviewQuestionsCategoryDetailPage(
    categoryId: number,
    seoFriendlyName: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<JobInterviewQuestionsCategoryDetailPage>;
  public getJobInterviewQuestionsCategoryDetailPage(
    categoryId: number,
    seoFriendlyName: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<JobInterviewQuestionsCategoryDetailPage>>;
  public getJobInterviewQuestionsCategoryDetailPage(
    categoryId: number,
    seoFriendlyName: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<JobInterviewQuestionsCategoryDetailPage>>;
  public getJobInterviewQuestionsCategoryDetailPage(
    categoryId: number,
    seoFriendlyName: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (categoryId === null || categoryId === undefined) {
      throw new Error(
        'Required parameter categoryId was null or undefined when calling getJobInterviewQuestionsCategoryDetailPage.'
      );
    }
    if (seoFriendlyName === null || seoFriendlyName === undefined) {
      throw new Error(
        'Required parameter seoFriendlyName was null or undefined when calling getJobInterviewQuestionsCategoryDetailPage.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<JobInterviewQuestionsCategoryDetailPage>(
      `${this.basePath}/job-interview-questions/c/${encodeURIComponent(String(categoryId))}/${encodeURIComponent(
        String(seoFriendlyName)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns data necessary for rendering job interview questions index page
   * @param keywords
   * @param categoryIds
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getJobInterviewQuestionsIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<JobInterviewQuestionsIndexPage>;
  public getJobInterviewQuestionsIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<JobInterviewQuestionsIndexPage>>;
  public getJobInterviewQuestionsIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<JobInterviewQuestionsIndexPage>>;
  public getJobInterviewQuestionsIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (keywords !== undefined) {
      queryParameters = queryParameters.set('keywords', <any>keywords);
    }
    if (categoryIds) {
      queryParameters = queryParameters.set('categoryIds', categoryIds.join(COLLECTION_FORMATS['csv']));
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<JobInterviewQuestionsIndexPage>(`${this.basePath}/job-interview-questions/index-page`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Saves the move result and returns the current state of the challenge
   * @param challengeUniqueIdentifier Challenge unique identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveMoveResult(challengeUniqueIdentifier: string, observe?: 'body', reportProgress?: boolean): Observable<Challenge>;
  public saveMoveResult(
    challengeUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Challenge>>;
  public saveMoveResult(
    challengeUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Challenge>>;
  public saveMoveResult(
    challengeUniqueIdentifier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (challengeUniqueIdentifier === null || challengeUniqueIdentifier === undefined) {
      throw new Error('Required parameter challengeUniqueIdentifier was null or undefined when calling saveMoveResult.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.post<Challenge>(
      `${this.basePath}/job-interview-questions/challenge/${encodeURIComponent(
        String(challengeUniqueIdentifier)
      )}/current-move/finish`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Starts the existing job interview question challnenge
   * @param challengeUniqueIdentifier Challenge unique identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startJobInterviewQuestionChallenge(
    challengeUniqueIdentifier: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<JobInterviewQuestionStartChallengeResponse>;
  public startJobInterviewQuestionChallenge(
    challengeUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<JobInterviewQuestionStartChallengeResponse>>;
  public startJobInterviewQuestionChallenge(
    challengeUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<JobInterviewQuestionStartChallengeResponse>>;
  public startJobInterviewQuestionChallenge(
    challengeUniqueIdentifier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (challengeUniqueIdentifier === null || challengeUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter challengeUniqueIdentifier was null or undefined when calling startJobInterviewQuestionChallenge.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.put<JobInterviewQuestionStartChallengeResponse>(
      `${this.basePath}/job-interview-questions/challenge/${encodeURIComponent(String(challengeUniqueIdentifier))}`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Updates an existing Job interview question
   * @param jobInterviewQuestionId Job interview question Identifier
   * @param body Job interview question object holding the updated data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateJobInterviewQuestion(
    jobInterviewQuestionId: number,
    body: JobInterviewQuestion,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateJobInterviewQuestion(
    jobInterviewQuestionId: number,
    body: JobInterviewQuestion,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateJobInterviewQuestion(
    jobInterviewQuestionId: number,
    body: JobInterviewQuestion,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateJobInterviewQuestion(
    jobInterviewQuestionId: number,
    body: JobInterviewQuestion,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error('Required parameter jobInterviewQuestionId was null or undefined when calling updateJobInterviewQuestion.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateJobInterviewQuestion.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/job-interview-questions/${encodeURIComponent(String(jobInterviewQuestionId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
