import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, UrlTree } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CurrentUserService } from 'src/app/modules/core/services/current-user.service';
import { Observable, from } from 'rxjs';
import { OpenAiService } from '../../services/ai/openai.service';
import { VoiceRecognitionService } from '../../services/voice-recognition/voice-recognition.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-ai-panel',
  templateUrl: './ai-panel.component.html',
  styleUrls: ['./ai-panel.component.scss'],
})
export class AiPanelComponent implements OnInit {
  form: UntypedFormGroup;
  timelineItems: FormArray;
  preview: string;
  buttonText: string = 'Start';
  chatGptVersions: string[] = ['gpt-3.5-turbo-0301', 'chat-gpt-4'];
  askingInProgress: boolean;

  constructor(
    private router: Router,
    private openAiService: OpenAiService,
    private voiceRecognitionService: VoiceRecognitionService,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  sayHello() {
    this.voiceRecognitionService.say(this.form.controls['result'].value);
  }

  ngOnInit() {
    this.createForm();

    this.voiceRecognitionService.init();

    this.voiceRecognitionService.onResult = () => {
      let text = this.voiceRecognitionService.text;
      this.form.controls['title'].setValue(text);
      this.addTimelineItem(text);
      this.changeDetectorRef.detectChanges();
    };

    this.voiceRecognitionService.onStop = () => {
      let text = this.voiceRecognitionService.text;
      this.form.controls['title'].setValue(text);
      this.addTimelineItem(text);
      this.updateToggleButtonState();
      this.changeDetectorRef.detectChanges();

      this.ask();
    };

    this.form.controls['title'].setValue('X');
  }

  createForm() {
    this.timelineItems = new FormArray([]);

    this.form = this.formBuilder.group({
      timelineItems: this.timelineItems,
      openAiApiKey: [''],
      chatGptVersion: [this.chatGptVersions[0]],
      title: [''],
      result: [''],
    });
  }

  getTimelineItemFieldValue(index: number, fieldName: string) {
    return this.timelineItems.controls[index].get(fieldName).value;
  }

  setTimelineItemFieldValue(index: number, fieldName: string, value: any) {
    return (this.timelineItems.controls[index] as UntypedFormGroup).controls[fieldName].setValue(value);
  }

  addTimelineItem(message: string = null) {
    const timelineItem = this.formBuilder.group({
      speakerIdentifier: '',
      message: message,
      notes: '',
      mode: message == null ? 'edit' : 'view',
      dateAdded: new Date(),
    });

    if (message != null) {
      //      timelineItem.get('message').prop .get('hidden').setValue(true);
    }

    this.timelineItems.push(timelineItem);
  }

  removeTimelineItem(index: number) {
    this.timelineItems.removeAt(index);
  }

  continuousRecording: boolean = false;

  onVoiceRecognitionToggle() {
    if (!this.voiceRecognitionService.isStarted) {
      this.form.controls['title'].setValue('');
      console.log('Starting voice recognition, continuous=' + this.continuousRecording);
      this.voiceRecognitionService.start(this.continuousRecording);
      this.startTimer();
    } else {
      this.voiceRecognitionService.stop();
      this.clearTimer();
    }
    this.updateToggleButtonState();
  }

  running: boolean;
  timerRef;
  counter: number;
  startTime: number;
  counterString: string;

  startTimer() {
    this.running = !this.running;
    if (this.running) {
      this.startTime = Date.now();
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - this.startTime;
        this.counterString = this.counter / 1000 + 's';
      }, 100);
    } else {
      clearInterval(this.timerRef);
    }
  }

  clearTimer() {
    this.running = false;
    this.counter = undefined;
    clearInterval(this.timerRef);
  }

  updateToggleButtonState() {
    console.log('recognition started: ' + this.voiceRecognitionService.isStarted);
    if (!this.voiceRecognitionService.isStarted) {
      this.buttonText = 'Start';
    } else {
      this.buttonText = 'Stop';
    }
  }

  changeChatGptVersion(e: any) {
    this.form.controls['chatGptVersion'].setValue(e.target.value, {
      onlySelf: true,
    });
  }

  ask = () => {
    this.askingInProgress = true;
    let prompt = this.form.controls['title'].value;

    let openAiApiKey = this.form.controls['openAiApiKey'].value;
    this.openAiService.setApiKey(openAiApiKey);

    let chatGptVersion = this.form.controls['chatGptVersion'].value;

    let chatGptMethod =
      chatGptVersion == 'chat-gpt-4' ? this.openAiService.generateTextChatGpt40 : this.openAiService.generateTextChatGpt35;

    console.log('xxx');
    console.log(chatGptMethod);
    console.log(this.openAiService);

    let self = this;
    this.form.controls['result'].setValue('');
    this.preview = '';
    from(chatGptMethod(prompt)).subscribe({
      next(result) {
        console.log('we have result');
        self.askingInProgress = false;
        var cleanedResult = result.trim();
        self.form.controls['result'].setValue(cleanedResult);
        self.preview = cleanedResult;
        self.changeDetectorRef.detectChanges();
      },
      error(msg) {
        var cleanedResult = msg;
        self.form.controls['result'].setValue(cleanedResult);
        self.preview = cleanedResult;
      },
    });
  };
}
