<div *ngIf="isAuthenticated" [hidden]="!isAuthenticated" method="post" id="logoutForm" class="navbar-right">
  <ul class="navbar-nav profile">
    <li class="nav-item">
      <app-authenticated-user-button></app-authenticated-user-button>
    </li>
    <li class="nav-item">
      <button class="hdr-btn mr-sm-1" (click)="signOut()">
        <span>
          <i class="fa fa-lock mr-1" aria-hidden="true"></i>
          Sign Out
        </span>
      </button>
    </li>
  </ul>
</div>
<ul *ngIf="!isAuthenticated" [hidden]="isAuthenticated" class="navbar-nav profile">
  <li class="nav-item">
    <app-sign-up-button></app-sign-up-button>
  </li>
  <li class="nav-item">
    <app-sign-in-button></app-sign-in-button>
    <!--<a id="log-in" data-toggle="modal" data-target=".modal-log-in"
       class="hdr-btn mr-2" href="#">
      <span>
        <i class="fa fa-unlock mr-1" aria-hidden="true"></i>
        Log In
      </span>
    </a>-->
    <script>
      (function () {
        //var loginUrl = '@Url.Action("LoginJson", "Account")';
        //var returnUrl = '@ViewData["ReturnUrl"]';
        //var loginController = new LoginController(loginUrl, returnUrl);
        // $("#log-in").unbind("click");
        // $("#log-in").click(function () {
        //     loginController.openModal();
        // });
      })();
    </script>
  </li>
</ul>
