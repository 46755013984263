import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadingService {
  private loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: any) {}

  lazyLoadCkEditor(): Observable<any> {
    return forkJoin([
      this.loadScript('ckeditor.js'),
      //      this.loadStyle('/assets/quill/quill.snow.css')
    ]);
  }

  lazyLoadSummerNote(): Observable<any> {
    return forkJoin([this.loadScript('summernote-bs4.min.js')]);
  }

  lazyLoadWebNotifications(): Observable<any> {
    return forkJoin([
      this.loadScript('web-notification.js'),
      //      this.loadStyle('/assets/quill/quill.snow.css')
    ]);
  }

  lazyLoadBootstrapDateTimePicker(): Observable<any> {
    return forkJoin([this.loadScript('bootstrap-datetimepicker.min')]);
  }

  private loadScript(url: string): Observable<any> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable();
    }

    this.loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this.loadedLibraries[url].next(null);
      this.loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this.loadedLibraries[url].asObservable();
  }

  private loadStyle(url: string): Observable<any> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable();
    }

    this.loadedLibraries[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this.loadedLibraries[url].next(null);
      this.loadedLibraries[url].complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    return this.loadedLibraries[url].asObservable();
  }
}
