import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var webkitSpeechRecognition: any;
declare var speechSynthesis: any;

@Injectable({
  providedIn: 'root',
})
export class VoiceRecognitionService {
  recognition: any;
  synth: any;
  isStoppedSpeechRecog = false;
  public text = '';
  transcripts = [];
  confidenceValues = [];
  isStarted = false;
  startedDate: number;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  init() {
    if (!isPlatformBrowser(this.platformId)) return;

    this.synth = speechSynthesis;

    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = false;
    this.recognition.interimResults = false;
    this.recognition.lang = 'en-US';

    this.recognition.onresult = (e: any) => {
      this.log('-----------------------result');
      this.log(e.results);

      //console.log(e.results);
      const transcript = Array.from(e.results)
        .filter((result: any) => result.isFinal)
        .map((result: any, i, items) => {
          if (i + 1 === items.length) {
            return result[0].transcript;
          } else {
            return '';
          }
        })
        .join('');
      //      this.log('*** transcript: ' + transcript);

      const confidence = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.confidence)
        .join('');

      // TODO: Delete previous test, just testing
      this.text = '';
      this.appendResult(transcript, confidence);
      this.handleOnResult();
    };

    this.recognition.onend = (condition: any) => {
      this.log('end event');
      this.recognition.stop();
      this.isStarted = false;
      this.handleOnStop();
    };
  }

  reset() {
    this.recognition.stop();
    this.text = '';
    this.transcripts = [];
    this.confidenceValues = [];
  }

  start(continuous: boolean = false) {
    this.reset();
    this.isStarted = true;
    this.startedDate = Date.now();
    this.recognition.continuous = continuous;
    this.recognition.start();
    this.log('Speech recognition started');
  }

  log(message: string) {
    let now = Date.now();
    const seconds = (now - this.startedDate) / 1000;
    console.log(new Date().toISOString() + ' (' + seconds + 's): ' + message);
  }

  stop() {
    if (!this.isStarted) return;
    this.recognition.stop();
  }

  handleOnStop() {
    if (this.onStop) this.onStop();
  }

  handleOnResult() {
    if (this.onResult) this.onResult();
  }

  public onStop: any;
  public onResult: any;

  appendResult(transcript: any, confidence: any) {
    this.log(transcript);
    this.transcripts.push(transcript);
    this.confidenceValues.push(confidence);

    this.text = this.text + ' ' + transcript;
  }

  say(message: string) {
    console.log('saying ' + message);
    const utterThis = new SpeechSynthesisUtterance(message);
    console.log(utterThis);
    console.log(this.synth);
    let voices = this.synth.getVoices();
    console.log(voices);
    for (var i = 0; i < voices.length; i++) {
      console.log(voices[i].name);
    }
    utterThis.voice = voices.find((p) => p.name === 'Google US English');
    utterThis.pitch = 1;
    utterThis.rate = 0.6;
    this.synth.speak(utterThis);
  }
}
