import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-links2',
  templateUrl: './footer-links2.component.html',
  styleUrls: ['./footer-links2.component.scss']
})
export class FooterLinks2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
