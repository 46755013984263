import { Injectable } from '@angular/core';

declare var webNotification: any;

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  private registration: any;

  constructor() {}

  hookServiceWorker() {
    if (this.registration === undefined) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        this.registration = registration;
      });
    }
  }

  public requestPermissions() {
    //manually ask for notification permissions (invoked automatically if needed and allowRequest=true)
    // from(window.Notification.requestPermission()).subscribe(
    //   (x) => {
    //     console.log(x);
    //   },
    //   () => {
    //     console.log("Does not have permission;")
    //   }
    // )
    // Notification.
    webNotification.requestPermission(function onRequest(granted) {
      if (granted) {
        console.log('Permission Granted.');
      } else {
        console.log('Permission Not Granted.');
      }
    });
  }

  public showNotification(title: string, body: string) {
    this.hookServiceWorker();
    this.requestPermissions();

    webNotification.showNotification(
      title,
      {
        serviceWorkerRegistration: this.registration,
        body: body,
        icon: 'https://www.bettercoder.io/favicon.ico',
        //image: 'https://www.bettercoder.io/favicon.ico',
        // actions: [
        //   {
        //     action: 'Start',
        //     title: 'Start'
        //   },
        //   {
        //     action: 'Stop',
        //     title: 'Stop'
        //   }
        // ],
        autoClose: 4000, //auto close the notification after 4 seconds (you can manually close it via hide function)
      },
      function onShow(error, hide) {
        if (error) {
          console.log('Unable to show notification: ' + error.message);
        } else {
          console.log('Notification Shown.');

          setTimeout(function hideNotification() {
            console.log('Hiding notification....');
            hide(); //manually close the notification (you can skip this if you use the autoClose option)
          }, 5000);
        }
      }
    );
  }
}
