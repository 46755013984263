import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRoleDirective } from './directives/has-role.directive';
import { SignInButtonComponent } from './components/layout/accounts/sign-in-button/sign-in-button.component';
import { InstallPwaComponent } from './components/install-pwa/install-pwa.component';
import { SignInDialogComponent } from './components/layout/accounts/sign-in-dialog/sign-in-dialog.component';
import { MenuBanner1Component } from './components/layout/menu/menu-banner1/menu-banner1.component';
import { MenuBanner2Component } from './components/layout/menu/menu-banner2/menu-banner2.component';
import { ChatIconComponent } from './components/chat-icon/chat-icon.component';
import { NotificationsIconComponent } from './components/notifications-icon/notifications-icon.component';
import { CoreRoutingModule } from './core.routing.module';
import { LoadingComponent } from './components/loading/loading.component';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MenuComponent } from './components/layout/menu/menu/menu.component';
import { MenuLoginPartialComponent } from './components/layout/menu/menu-login-partial/menu-login-partial.component';
import { SignInFormComponent } from './components/layout/accounts/sign-in-form/sign-in-form.component';
import { SignUpButtonComponent } from './components/layout/accounts/sign-up-button/sign-up-button.component';
import { AuthenticatedUserButtonComponent } from './components/layout/accounts/authenticated-user-button/authenticated-user-button.component';
import { FooterComponent } from './components/layout/footer/footer/footer.component';
import { GoToTopComponent } from './components/layout/footer/go-to-top/go-to-top.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FooterLinks1Component } from './components/layout/footer/footer-links1/footer-links1.component';
import { FooterLinks2Component } from './components/layout/footer/footer-links2/footer-links2.component';
import { FooterNewsletterComponent } from './components/layout/footer/footer-newsletter/footer-newsletter.component';
import { FooterYearComponent } from './components/layout/footer/footer-year/footer-year.component';
import { JobInterviewQuestionChallengeDialogComponent } from './components/job-interview-question-challenge-dialog/job-interview-question-challenge-dialog.component';
import { StartTestComponent } from './components/start-test/start-test.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { safePipe } from './pipes/safe.pipe';
import { JobInterviewQuestionAnswersComponent } from './components/job-interview-question-answers/job-interview-question-answers.component';
import { NotificationModule } from '../notification/Notification.module';
import { StructurePublicComponent } from './components/structure-public/structure-public.component';
import { AiPanelComponent } from './components/ai-panel/ai-panel.component';
import { BlogComponent } from './components/blog/blog.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    BlogComponent,
    ChatIconComponent,
    HasRoleDirective,
    GoToTopComponent,
    FooterComponent,
    FooterLinks1Component,
    FooterLinks2Component,
    FooterNewsletterComponent,
    FooterYearComponent,
    GoToTopComponent,
    JobInterviewQuestionChallengeDialogComponent,
    LoadingComponent,
    ValidationSummaryComponent,
    InstallPwaComponent,
    DashboardComponent,
    MenuComponent,
    MenuBanner1Component,
    MenuBanner2Component,
    MenuLoginPartialComponent,
    SignInButtonComponent,
    SignInDialogComponent,
    NotificationsIconComponent,
    SignInFormComponent,
    SignUpButtonComponent,
    AuthenticatedUserButtonComponent,
    StartTestComponent,
    SignUpButtonComponent,
    JobInterviewQuestionAnswersComponent,
    safePipe,
    StructurePublicComponent,
    AiPanelComponent,
  ],
  // Do not add singletons here, they should be registered using @Injectable({ providedIn: 'root' })
  providers: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreRoutingModule,
    ClipboardModule,
    NgbModule,
    NotificationModule,
    RouterModule,
    NotificationModule,
  ],
  // Do not add Providers/Services, they are app-wide when decorated by @Injectable
  exports: [
    safePipe,
    AuthenticatedUserButtonComponent,
    BlogComponent,
    ChatIconComponent,
    LoadingComponent,
    HasRoleDirective,
    InstallPwaComponent,
    JobInterviewQuestionChallengeDialogComponent,
    MenuComponent,
    MenuBanner1Component,
    MenuBanner2Component,
    FooterComponent,
    GoToTopComponent,
    MenuLoginPartialComponent,
    NotificationsIconComponent,
    SignInFormComponent,
    SignInButtonComponent,
    SignInDialogComponent,
    SignUpButtonComponent,
    StartTestComponent,
    ValidationSummaryComponent,
    JobInterviewQuestionAnswersComponent,
    // Modules
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    NotificationModule,
  ],
})
export class CoreModule {
  constructor() {}
}
