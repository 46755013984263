/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { TeamUser } from '../model/teamUser';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class TeamUsersService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Removes a team user from the team
   * @param teamId Team Identifier
   * @param userId User Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteTeamUser(teamId: number, userId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteTeamUser(
    teamId: number,
    userId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteTeamUser(teamId: number, userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteTeamUser(teamId: number, userId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (teamId === null || teamId === undefined) {
      throw new Error('Required parameter teamId was null or undefined when calling deleteTeamUser.');
    }
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling deleteTeamUser.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(
      `${this.basePath}/teams/${encodeURIComponent(String(teamId))}/users/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns all team users
   * @param teamId Team Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTeamUsers(teamId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TeamUser>>;
  public getTeamUsers(teamId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeamUser>>>;
  public getTeamUsers(teamId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeamUser>>>;
  public getTeamUsers(teamId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (teamId === null || teamId === undefined) {
      throw new Error('Required parameter teamId was null or undefined when calling getTeamUsers.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<TeamUser>>(`${this.basePath}/teams/${encodeURIComponent(String(teamId))}/users`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
