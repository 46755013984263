/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { ExternalSignInResponse } from '../model/externalSignInResponse';
import { SignInRequest } from '../model/signInRequest';
import { SignInResponse } from '../model/signInResponse';
import { SignUpRequest } from '../model/signUpRequest';
import { SignUpResponse } from '../model/signUpResponse';
import { UserPoints } from '../model/userPoints';
import { UserProfile } from '../model/userProfile';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class AccountsService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Signs the user in using the authentication details retrieved from external authority
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public externalSignIn(observe?: 'body', reportProgress?: boolean): Observable<ExternalSignInResponse>;
  public externalSignIn(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExternalSignInResponse>>;
  public externalSignIn(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExternalSignInResponse>>;
  public externalSignIn(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<ExternalSignInResponse>(`${this.basePath}/accounts/external-sign-in`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Gets number of points of the logged user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserPoints(observe?: 'body', reportProgress?: boolean): Observable<UserPoints>;
  public getUserPoints(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPoints>>;
  public getUserPoints(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPoints>>;
  public getUserPoints(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<UserPoints>(`${this.basePath}/accounts/user-points`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Gets number of points of the top users with the higher number of points
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserPointsLeaderBoard(observe?: 'body', reportProgress?: boolean): Observable<Array<UserPoints>>;
  public getUserPointsLeaderBoard(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserPoints>>>;
  public getUserPointsLeaderBoard(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserPoints>>>;
  public getUserPointsLeaderBoard(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<UserPoints>>(`${this.basePath}/accounts/user-points/leaderboard`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Gets the user profile of the signed in user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserProfile(observe?: 'body', reportProgress?: boolean): Observable<UserProfile>;
  public getUserProfile(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserProfile>>;
  public getUserProfile(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserProfile>>;
  public getUserProfile(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<UserProfile>(`${this.basePath}/accounts/user-profile`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Signs the user in
   * @param body Sign-in credentials to be verified on the server
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public signIn(body: SignInRequest, observe?: 'body', reportProgress?: boolean): Observable<SignInResponse>;
  public signIn(body: SignInRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignInResponse>>;
  public signIn(body: SignInRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignInResponse>>;
  public signIn(body: SignInRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling signIn.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SignInResponse>(`${this.basePath}/accounts/sign-in`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Signs the user out
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public signOut(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public signOut(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public signOut(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public signOut(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(`${this.basePath}/accounts/sign-out`, null, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Signs the user up
   * @param body Sign up data to be submitted to the server
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public signUp(body: SignUpRequest, observe?: 'body', reportProgress?: boolean): Observable<SignUpResponse>;
  public signUp(body: SignUpRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SignUpResponse>>;
  public signUp(body: SignUpRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SignUpResponse>>;
  public signUp(body: SignUpRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling signUp.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SignUpResponse>(`${this.basePath}/accounts/sign-up`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Updates a user profile
   * @param body User profile object holding the updated data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateUserProfile(body: UserProfile, observe?: 'body', reportProgress?: boolean): Observable<UserProfile>;
  public updateUserProfile(
    body: UserProfile,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserProfile>>;
  public updateUserProfile(body: UserProfile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserProfile>>;
  public updateUserProfile(body: UserProfile, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateUserProfile.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<UserProfile>(`${this.basePath}/accounts/user-profile`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
