import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { AccountsService } from '../../../api/api/api';
import { UserProfile } from '../../../api/model/models';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from '@src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  isAuthenticated$ = new BehaviorSubject<boolean>(false);
  userProfile$ = new BehaviorSubject<UserProfile>(null);
  created;
  authCookie: Object;

  private STORAGE_KEY_HAS_AUTHENTICATION_TOKEN: string = 'hasAuthenticationToken';
  private userProfile: UserProfile;
  private hasAuthenticationToken: boolean;

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
    private accountsService: AccountsService) {
    this.created = new Date().valueOf();
    this.hasAuthenticationToken = this.storage.get(this.STORAGE_KEY_HAS_AUTHENTICATION_TOKEN) == "1";
    if (this.hasAuthenticationToken) {
      this.setIsAuthenticated(true);
    }
  }

  public setIsAuthenticated(isAuthenticated: boolean) {
    this.hasAuthenticationToken = isAuthenticated;

    if (!isAuthenticated) {
      this.userProfile = null;
    }
    else {
      this.fetchUserProfile();
    }

    this.isAuthenticated$.next(this.hasAuthenticationToken);
  }

  public isAuthenticated(): Observable<boolean> {
    return this.isAuthenticated$.asObservable();

    // if (this.hasUserProfile) {
    //   return of(this.hasUserProfile);
    // }

    // return this.fetchUser().pipe(
    //   catchError((error) => {
    //     return of(false);
    //   }),
    //   map((p) => {
    //     return this.hasUserProfile;
    //   })
    // );
  }

  public getUser(): Observable<UserProfile> {
    if (!this.hasUserProfile()) {
      this.fetchUserProfile();
    }

    return this.userProfile$.asObservable();
  }

  private fetchUserProfile() {
    this.accountsService.getUserProfile().subscribe(userProfile => {
      this.storage.set(this.STORAGE_KEY_HAS_AUTHENTICATION_TOKEN, "1");
      this.userProfile = userProfile;
      this.userProfile$.next(userProfile);
    },
      (error) => {
        this.setIsAuthenticated(false);
      });
  }

  private hasUserProfile(): boolean {
    return this.userProfile != null;
  }

  public login(authority) {
  }
}