import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';

import { AccountsService } from './api/accounts.service';
import { AudioTextToSpeechService } from './api/audioTextToSpeech.service';
import { BestPracticeCategoriesService } from './api/bestPracticeCategories.service';
import { BestPracticesService } from './api/bestPractices.service';
import { BlogService } from './api/blog.service';
import { ChallengesService } from './api/challenges.service';
import { ChannelsService } from './api/channels.service';
import { CommentsService } from './api/comments.service';
import { CompaniesService } from './api/companies.service';
import { FeedbacksService } from './api/feedbacks.service';
import { JobInterviewQuestionAnswerAudioFilesService } from './api/jobInterviewQuestionAnswerAudioFiles.service';
import { JobInterviewQuestionCategoriesService } from './api/jobInterviewQuestionCategories.service';
import { JobInterviewQuestionListsService } from './api/jobInterviewQuestionLists.service';
import { JobInterviewQuestionsService } from './api/jobInterviewQuestions.service';
import { LearningJournalEntriesService } from './api/learningJournalEntries.service';
import { LearningJournalsService } from './api/learningJournals.service';
import { LinksService } from './api/links.service';
import { MessagingListUsersService } from './api/messagingListUsers.service';
import { NotificationCenterService } from './api/notificationCenter.service';
import { QuestionOfTheDayService } from './api/questionOfTheDay.service';
import { StructuresService } from './api/structures.service';
import { StudentProfilesService } from './api/studentProfiles.service';
import { TagsService } from './api/tags.service';
import { TeamInvitationsService } from './api/teamInvitations.service';
import { TeamUsersService } from './api/teamUsers.service';
import { TeamsService } from './api/teams.service';
import { TestRunsService } from './api/testRuns.service';
import { TestsService } from './api/tests.service';
import { WebPushService } from './api/webPush.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    AccountsService,
    AudioTextToSpeechService,
    BestPracticeCategoriesService,
    BestPracticesService,
    BlogService,
    ChallengesService,
    ChannelsService,
    CommentsService,
    CompaniesService,
    FeedbacksService,
    JobInterviewQuestionAnswerAudioFilesService,
    JobInterviewQuestionCategoriesService,
    JobInterviewQuestionListsService,
    JobInterviewQuestionsService,
    LearningJournalEntriesService,
    LearningJournalsService,
    LinksService,
    MessagingListUsersService,
    NotificationCenterService,
    QuestionOfTheDayService,
    StructuresService,
    StudentProfilesService,
    TagsService,
    TeamInvitationsService,
    TeamUsersService,
    TeamsService,
    TestRunsService,
    TestsService,
    WebPushService,
  ],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import your base AppModule only.');
    }
  }
}
