<div id="index-header">
  <div class="container text-center py-3 py-md-4">
    <h1>
      <strong>Learn Software Development</strong>
      <br />
      Build Your Career, Change the World
    </h1>
    <p>
      At Better Coder, we provide effective training tools to guide your learning journey. Hone your skills daily and ascend to
      the ranks of successful experts.
    </p>
    <div class="row">
      <div class="col-lg-3">
        <a [routerLink]="['/tests']" class="btn btn-success btn-lg btn-block">
          <i class="fa fa-magic"></i>
          Knowledge tests
        </a>
      </div>
      <div class="col-lg-3">
        <a [routerLink]="['/job-interview-questions']" class="btn btn-success btn-lg btn-block">
          <i class="fa fa-database"></i>
          Job Interview Questions
        </a>
      </div>
      <div class="col-lg-3">
        <a [routerLink]="['/best-practices']" asp-action="Index" class="btn btn-success btn-lg btn-block">
          <i class="fa fa-check-circle"></i>
          Best Practices
        </a>
      </div>
      <div class="col-lg-3">
        <a [routerLink]="['/lp/learning-journals']" class="btn btn-success btn-lg btn-block">
          <i class="fa fa-lightbulb"></i>
          Learning Journals
        </a>
      </div>
    </div>
  </div>
</div>
<div class="container index-promo mt-4">
  <div class="row">
    <div class="col-md-12 text-center">
      <h2><a [routerLink]="['/job-interview-questions']">The World's biggest Job Interview Question Database</a></h2>
      <div class="divider-line"></div>
      <h3 class="sub-title">Excel in job interviews like a true star</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="rounded-box text-center">
        <div class="single-icon"><i class="fa fa-database"></i></div>
        <h4>
          <a [routerLink]="['/job-interview-questions']">Thousands of job interview questions</a>
        </h4>
        <p>
          We continuously gather job interview questions along with their answers and relevant learning resources. You'll find the
          latest questions that allow you to identify and fill any knowledge gaps with ease.
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="rounded-box text-center">
        <div class="single-icon"><i class="fa fa-address-book"></i></div>
        <h4>
          <a [routerLink]="['/tests']">Tests to Challenge Your Skills</a>
        </h4>
        <p>
          Put yourself to the test and evaluate your skill level. Learn new things and compete with the others to get into the
          hall of fame. Have fun and strive to become a better coder than your colleagues.
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div class="rounded-box text-center">
        <div class="single-icon"><i class="fa fa-archive"></i></div>
        <h4>
          <a [routerLink]="['/job-interview-questions']">Ace Job Interviews with Our Top-notch Training Tools</a>
        </h4>
        <p>
          By analyzing our comprehensive collection of job interview questions, you can gain valuable insights into the market's
          knowledge demands. Using our training tools you can gain required knowledge efficiently.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="rounded-box">
        <div class="row">
          <div class="col-md-6">
            <h2>
              <a [routerLink]="['/job-interview-questions']">Job Interview Question Browser</a>
            </h2>
            <ul>
              <li>The largest compendium of questions all in one place</li>
              <li>Answers to questions are available</li>
              <li>Links to more detailed answer-related resources</li>
              <li>Automatically created question list based on a job ad</li>
              <li>Up-to-date questions and technologies</li>
              <li>Easy browsing and navigation</li>
              <li>Powerful searching and advanced filtering</li>
              <li>Categorization and tagging</li>
              <li>Seniority level</li>
              <li>Community discussion</li>
            </ul>
          </div>
          <div class="col-md-6">
            <h2>
              <a [routerLink]="['/job-interview-questions']">Handy e-learning features</a>
            </h2>
            <ul>
              <li>Mobile ready, pleasant to read on any device</li>
              <li>Favorite questions</li>
              <li>Custom-built question sets</li>
              <li>Add user notes to questions</li>
              <li>Blind printable question sets/tests</li>
              <li>Question by e-mail</li>
              <li>Random question picker</li>
              <li>Community discussions</li>
              <li>Building your own team</li>
              <li>Promote collaborative learning by sharing resources within your team</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-center" style="margin-top: 30px">
      <h2>
        <a [routerLink]="['/job-interview-questions']">Use all awesome features for free</a>
      </h2>
      <div class="divider-line"></div>
      <h3 class="sub-title">Keep up with the pace of technology and streamline your learning process</h3>
      <a class="btn btn-secondary btn-green" [routerLink]="['/job-interview-questions']">Go to Job Interview Question Browser</a>
    </div>
  </div>

  <app-blog></app-blog>
</div>
