<h3>Embark on Your Journey to Becoming a Better Coder</h3>
<ul>
  <li>
    <a [routerLink]="['/job-interview-questions/c/19/asp-net-core']">ASP.NET Core Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/20/net-core']">.NET Core Job Inerview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/2/c']">C# Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/3/asp-net-mvc']">ASP.NET MVC Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/13/qa']">QA Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/4/html']">HTML Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/13/html-for-beginners']">HTML Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/cheat-sheets/html']">HTML Cheat Sheet</a>
  </li>
  <li>
    <a [routerLink]="['/best-practices/c/4/html']">HTML Best Practices</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/24/css']">CSS Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/15/css-for-beginners']">CSS Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/cheat-sheets/css']">CSS Cheat Sheet</a>
  </li>
  <li>
    <a [routerLink]="['/best-practices/c/19/css']">CSS Best Practices</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/23/git']">JavaScript Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/tests/16/git-for-beginners']">Git Test for Beginners</a>
  </li>
  <li>
    <a [routerLink]="['/cheat-sheets/git-for-beginners']">Git for Beginners - Cheat Sheet</a>
  </li>
  <li>
    <a [routerLink]="['/job-interview-questions/c/5/javascript']">JavaScript Job Interview Questions</a>
  </li>
  <li>
    <a [routerLink]="['/learningpaths/junior-front-end-developer']">Learning Path for Junior Front-end Developer</a>
  </li>
  <li>
    <a [routerLink]="['/learningpaths/junior-dot-net-developer']">Learning Path for .NET/C# Back-end Developer</a>
  </li>
  <li>
    <a [routerLink]="['/learningpaths/angular-developer']">Learning Path for Angular Developer</a>
  </li>
</ul>
<hr />
<p>
  Build your dream team(s) and leverage the power of collective knowledge. Communicate with your team mates and get better
  together.
</p>

<a [routerLink]="['/dashboard']" title="Dashboard" class="btn btn-secondary btn-block mb-3">
  <i class="fa fa-users" aria-hidden="true"></i>
  Go to Your Account Dashboard
</a>

<a [routerLink]="['/accounts/sign-up']" title="Sign Up" class="btn btn-secondary btn-block">
  <i class="fa fa-users" aria-hidden="true"></i>
  Create an account for free
</a>
