import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@api/modules/notification/Notification.service';

@Component({
  selector: 'app-footer-newsletter',
  templateUrl: './footer-newsletter.component.html',
  styleUrls: ['./footer-newsletter.component.scss'],
})
export class FooterNewsletterComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      email: [null, Validators.required],
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    let formData = this.form.value;

    let data = {
      email: formData.email,
      mailingListGuids: ['106c1aef-ff93-47e7-937c-a0ed65cdd29f'],
    };
    let url = 'https://m.bettercoder.io/m/sc/?d=nScgraQ4PBmlglXxPFlrDc7ZdnnX1QfenSxFnj9abmqo1rBIuVS5TQ%3d%3d';

    this.http.post<any>(url, data).subscribe(
      (result) => {
        this.notificationService.success('Your e-mail subscription request was successfully sent.');
        this.form.get('email').reset();
      },
      (error) => {
        this.notificationService.error('Could not send your e-mail subscription request.');
      }
    );
  }
}
