<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="offset-md-1 col-md-3">
        <app-footer-links1></app-footer-links1>
      </div>
      <div class="col-md-4 pb-5">
        <app-footer-links2></app-footer-links2>
      </div>
      <div class="col-md-3 newsletter pb-5">
        <app-footer-newsletter></app-footer-newsletter>
      </div>
    </div>
  </div>
  <app-footer-year></app-footer-year>
</footer>
