import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-go-to-top',
  templateUrl: './go-to-top.component.html',
  styleUrls: ['./go-to-top.component.scss'],
})
export class GoToTopComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
