import { Component, OnInit, Input } from '@angular/core';
import { CurrentUserService } from 'src/app/modules/core/services/current-user.service';
import { Router } from '@angular/router';
import { LoggingService } from '@api/modules/core/services/logging/LoggingService';

@Component({
  selector: 'app-authenticated-user-button',
  templateUrl: './authenticated-user-button.component.html',
  styleUrls: ['./authenticated-user-button.component.scss'],
})
export class AuthenticatedUserButtonComponent implements OnInit {
  @Input() displayName: string;

  constructor(private currentUserService: CurrentUserService, private router: Router, private loggingService: LoggingService) {}

  ngOnInit(): void {
    this.currentUserService.userProfile$.subscribe(
      (userProfile) => {
        if (userProfile != null) {
          this.displayName = userProfile.displayName;
        }
      },
      (error) => this.loggingService.error(error)
    );
  }
}
