/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BestPractice } from '../model/bestPractice';
import { BestPracticesPublicCategoryDetailPage } from '../model/bestPracticesPublicCategoryDetailPage';
import { BestPracticesPublicDetailPage } from '../model/bestPracticesPublicDetailPage';
import { BestPracticesPublicIndexPage } from '../model/bestPracticesPublicIndexPage';
import { ErrorResponse } from '../model/errorResponse';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class BestPracticesService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Creates a new best practice
   * @param body Best practice object that needs to be added
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addBestPractice(body: BestPractice, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public addBestPractice(body: BestPractice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public addBestPractice(body: BestPractice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public addBestPractice(body: BestPractice, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addBestPractice.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/best-practices-admin`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Deletes a best practice
   * @param bestPracticeId Best Practice Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteBestPractice(bestPracticeId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteBestPractice(
    bestPracticeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteBestPractice(bestPracticeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteBestPractice(bestPracticeId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (bestPracticeId === null || bestPracticeId === undefined) {
      throw new Error('Required parameter bestPracticeId was null or undefined when calling deleteBestPractice.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(`${this.basePath}/best-practices-admin/${encodeURIComponent(String(bestPracticeId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Returns a best practice by id
   * @param bestPracticeId Best Practice Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBestPractice(bestPracticeId: number, observe?: 'body', reportProgress?: boolean): Observable<BestPractice>;
  public getBestPractice(
    bestPracticeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BestPractice>>;
  public getBestPractice(
    bestPracticeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BestPractice>>;
  public getBestPractice(bestPracticeId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (bestPracticeId === null || bestPracticeId === undefined) {
      throw new Error('Required parameter bestPracticeId was null or undefined when calling getBestPractice.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<BestPractice>(
      `${this.basePath}/best-practices-admin/${encodeURIComponent(String(bestPracticeId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Gets a list of best practices
   * @param keywords
   * @param categoryIds
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBestPractices(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<BestPractice>>;
  public getBestPractices(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<BestPractice>>>;
  public getBestPractices(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<BestPractice>>>;
  public getBestPractices(
    keywords?: string,
    categoryIds?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (keywords !== undefined) {
      queryParameters = queryParameters.set('keywords', <any>keywords);
    }
    if (categoryIds) {
      queryParameters = queryParameters.set('categoryIds', categoryIds.join(COLLECTION_FORMATS['csv']));
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<BestPractice>>(`${this.basePath}/best-practices-admin`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Gets a best practice category page with list of best practices
   * @param bestPracticeCategoryId Best practice category identifier
   * @param seoFriendlyName URL-format name in SEO friendly format
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBestPracticesPublicCategoryDetailPage(
    bestPracticeCategoryId: number,
    seoFriendlyName: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BestPracticesPublicCategoryDetailPage>;
  public getBestPracticesPublicCategoryDetailPage(
    bestPracticeCategoryId: number,
    seoFriendlyName: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BestPracticesPublicCategoryDetailPage>>;
  public getBestPracticesPublicCategoryDetailPage(
    bestPracticeCategoryId: number,
    seoFriendlyName: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BestPracticesPublicCategoryDetailPage>>;
  public getBestPracticesPublicCategoryDetailPage(
    bestPracticeCategoryId: number,
    seoFriendlyName: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bestPracticeCategoryId === null || bestPracticeCategoryId === undefined) {
      throw new Error(
        'Required parameter bestPracticeCategoryId was null or undefined when calling getBestPracticesPublicCategoryDetailPage.'
      );
    }
    if (seoFriendlyName === null || seoFriendlyName === undefined) {
      throw new Error(
        'Required parameter seoFriendlyName was null or undefined when calling getBestPracticesPublicCategoryDetailPage.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<BestPracticesPublicCategoryDetailPage>(
      `${this.basePath}/best-practices/c/${encodeURIComponent(String(bestPracticeCategoryId))}/${encodeURIComponent(
        String(seoFriendlyName)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Gets a list of best practices
   * @param bestPracticeId Best practice identifier
   * @param seoFriendlyName URL-format name in SEO friendly format
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBestPracticesPublicDetailPage(
    bestPracticeId: number,
    seoFriendlyName: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BestPracticesPublicDetailPage>;
  public getBestPracticesPublicDetailPage(
    bestPracticeId: number,
    seoFriendlyName: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BestPracticesPublicDetailPage>>;
  public getBestPracticesPublicDetailPage(
    bestPracticeId: number,
    seoFriendlyName: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BestPracticesPublicDetailPage>>;
  public getBestPracticesPublicDetailPage(
    bestPracticeId: number,
    seoFriendlyName: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bestPracticeId === null || bestPracticeId === undefined) {
      throw new Error('Required parameter bestPracticeId was null or undefined when calling getBestPracticesPublicDetailPage.');
    }
    if (seoFriendlyName === null || seoFriendlyName === undefined) {
      throw new Error('Required parameter seoFriendlyName was null or undefined when calling getBestPracticesPublicDetailPage.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<BestPracticesPublicDetailPage>(
      `${this.basePath}/best-practices/${encodeURIComponent(String(bestPracticeId))}/${encodeURIComponent(
        String(seoFriendlyName)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Gets a list of best practices
   * @param keywords
   * @param categoryIds
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBestPracticesPublicIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BestPracticesPublicIndexPage>;
  public getBestPracticesPublicIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BestPracticesPublicIndexPage>>;
  public getBestPracticesPublicIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BestPracticesPublicIndexPage>>;
  public getBestPracticesPublicIndexPage(
    keywords?: string,
    categoryIds?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (keywords !== undefined) {
      queryParameters = queryParameters.set('keywords', <any>keywords);
    }
    if (categoryIds) {
      queryParameters = queryParameters.set('categoryIds', categoryIds.join(COLLECTION_FORMATS['csv']));
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<BestPracticesPublicIndexPage>(`${this.basePath}/best-practices`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Updates an existing best practice
   * @param bestPracticeId Best Practice Identifier
   * @param body Best Practice object holding the updated data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBestPractice(
    bestPracticeId: number,
    body: BestPractice,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateBestPractice(
    bestPracticeId: number,
    body: BestPractice,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateBestPractice(
    bestPracticeId: number,
    body: BestPractice,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateBestPractice(
    bestPracticeId: number,
    body: BestPractice,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bestPracticeId === null || bestPracticeId === undefined) {
      throw new Error('Required parameter bestPracticeId was null or undefined when calling updateBestPractice.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateBestPractice.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/best-practices-admin/${encodeURIComponent(String(bestPracticeId))}`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
