import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { BlogEntry, BlogService } from '@api/api';
import { SeoService } from '@api/modules/core/services/seo/seo.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogEntries: BlogEntry[] = [];

  constructor(private blogService: BlogService,
    private seoService: SeoService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.blogService.getBlogEntries().subscribe(entries => {
      this.blogEntries = entries;
    });
  }

  seoFriendlyName(input: string) {
    return this.seoService.seoFriendly(input);
  }
}