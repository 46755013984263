/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { JobInterviewQuestionAnswerAudioFile } from '../model/jobInterviewQuestionAnswerAudioFile';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class JobInterviewQuestionAnswerAudioFilesService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Creates a new job interview question answer audio file
   * @param jobInterviewQuestionId Job interview question identifier
   * @param file The file to upload.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionId: number,
    file?: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public addJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionId: number,
    file?: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionId: number,
    file?: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionId: number,
    file?: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error(
        'Required parameter jobInterviewQuestionId was null or undefined when calling addJobInterviewQuestionAnswerAudioFile.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (file !== undefined) {
      formParams.append('file', <any>file);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/job-interview-questions-audio/q/${encodeURIComponent(String(jobInterviewQuestionId))}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Creates a new job interview question answer audio file
   * @param jobInterviewQuestionId Job interview question identifier
   * @param testRunUniqueIdentifier Test run unique identifier
   * @param file The file to upload.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addJobInterviewQuestionAnswerAudioFileToTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    file?: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public addJobInterviewQuestionAnswerAudioFileToTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    file?: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addJobInterviewQuestionAnswerAudioFileToTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    file?: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addJobInterviewQuestionAnswerAudioFileToTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    file?: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error(
        'Required parameter jobInterviewQuestionId was null or undefined when calling addJobInterviewQuestionAnswerAudioFileToTestRun.'
      );
    }
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter testRunUniqueIdentifier was null or undefined when calling addJobInterviewQuestionAnswerAudioFileToTestRun.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (file !== undefined) {
      formParams.append('file', <any>file);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/job-interview-questions-audio/q/${encodeURIComponent(
        String(jobInterviewQuestionId)
      )}/${encodeURIComponent(String(testRunUniqueIdentifier))}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Deletes a job interview question answer audio file by id
   * @param jobInterviewQuestionAnswerAudioFileId Job interview question answer audio file identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionAnswerAudioFileId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionAnswerAudioFileId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionAnswerAudioFileId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteJobInterviewQuestionAnswerAudioFile(
    jobInterviewQuestionAnswerAudioFileId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionAnswerAudioFileId === null || jobInterviewQuestionAnswerAudioFileId === undefined) {
      throw new Error(
        'Required parameter jobInterviewQuestionAnswerAudioFileId was null or undefined when calling deleteJobInterviewQuestionAnswerAudioFile.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(
      `${this.basePath}/job-interview-questions-audio/${encodeURIComponent(String(jobInterviewQuestionAnswerAudioFileId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a list of audio files for job interview questions
   * @param jobInterviewQuestionId Job interview question identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getJobInterviewQuestionAnswerAudioFiles(
    jobInterviewQuestionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<JobInterviewQuestionAnswerAudioFile>>;
  public getJobInterviewQuestionAnswerAudioFiles(
    jobInterviewQuestionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<JobInterviewQuestionAnswerAudioFile>>>;
  public getJobInterviewQuestionAnswerAudioFiles(
    jobInterviewQuestionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<JobInterviewQuestionAnswerAudioFile>>>;
  public getJobInterviewQuestionAnswerAudioFiles(
    jobInterviewQuestionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error(
        'Required parameter jobInterviewQuestionId was null or undefined when calling getJobInterviewQuestionAnswerAudioFiles.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<JobInterviewQuestionAnswerAudioFile>>(
      `${this.basePath}/job-interview-questions-audio/q/${encodeURIComponent(String(jobInterviewQuestionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a list of audio files for job interview questions
   * @param jobInterviewQuestionId Job interview question identifier
   * @param testRunUniqueIdentifier Test run unique identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getJobInterviewQuestionAnswerAudioFilesForTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<JobInterviewQuestionAnswerAudioFile>>;
  public getJobInterviewQuestionAnswerAudioFilesForTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<JobInterviewQuestionAnswerAudioFile>>>;
  public getJobInterviewQuestionAnswerAudioFilesForTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<JobInterviewQuestionAnswerAudioFile>>>;
  public getJobInterviewQuestionAnswerAudioFilesForTestRun(
    jobInterviewQuestionId: number,
    testRunUniqueIdentifier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobInterviewQuestionId === null || jobInterviewQuestionId === undefined) {
      throw new Error(
        'Required parameter jobInterviewQuestionId was null or undefined when calling getJobInterviewQuestionAnswerAudioFilesForTestRun.'
      );
    }
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter testRunUniqueIdentifier was null or undefined when calling getJobInterviewQuestionAnswerAudioFilesForTestRun.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<JobInterviewQuestionAnswerAudioFile>>(
      `${this.basePath}/job-interview-questions-audio/q/${encodeURIComponent(
        String(jobInterviewQuestionId)
      )}/${encodeURIComponent(String(testRunUniqueIdentifier))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
