import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { zone } from 'zone.js/dist/zone';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BetterCoderManager } from './app/microfrontend/bettercoder-manager';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
      console.error('Global error: ' + err);
    });
});
