<div class="card">
  <div class="card-body bg-dark">
    <p>Stay on the cutting edge with the latest news from Better Coder. Subscribe to our newsletter now.</p>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="form-group">
        <label for="email" class="text-white">E-mail</label>
        <input type="text" id="email" class="form-control" formControlName="email" />
      </div>
      <div class="form-group text-center">
        <button type="submit" class="btn btn-secondary">Subscribe</button>
      </div>
    </form>
  </div>
</div>
