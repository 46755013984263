import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StructurePublic, StructuresService } from '@api/api';
import { MetaDataService } from '../../services/metadata/metadata.service';

@Component({
  selector: 'app-structure-public',
  templateUrl: './structure-public.component.html',
  styleUrls: ['./structure-public.component.scss'],
})
export class StructurePublicComponent implements OnInit {
  model: any;
  isLoading: boolean;

  constructor(
    private metaDataService: MetaDataService,
    private structuresService: StructuresService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    let structureConstant = this.activatedRoute.root.firstChild.snapshot.data['structureConstant'];
    this.structuresService
      .getPublicStructureByConstant(structureConstant)
      .subscribe((structure) => {
        this.model = structure;
      })
      .add(() => {
        this.isLoading = false;
      });
  }
}
