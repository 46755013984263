<a
  *ngIf="isAuthenticated$ | async"
  [hidden]="!(isAuthenticated$ | async)"
  ng-cloak
  class="nav-link pointer px-3 notification-badge-wrapper"
  (click)="toggleNotifications()"
>
  <i
    class="fas fa-bell fa-sm"
    [ngClass]="{
      opened: notificationsOpened,
      active: newNotificationCount > 0
    }"
  ></i>
  <span class="badge badge-danger px-1" *ngIf="newNotificationCount > 0" [ngClass]="{ breathing: breathingActive }">
    {{ newNotificationCount }}
  </span>
</a>
<router-outlet name="notifications"></router-outlet>
