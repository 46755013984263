/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { LearningJournalEntry } from '../model/learningJournalEntry';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class LearningJournalEntriesService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Creates a new learning journal entry
   * @param learningJournalId Learing Journal Identifier
   * @param body Learning journal entry object that needs to be added
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addLearningJournalEntry(
    learningJournalId: number,
    body: LearningJournalEntry,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public addLearningJournalEntry(
    learningJournalId: number,
    body: LearningJournalEntry,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addLearningJournalEntry(
    learningJournalId: number,
    body: LearningJournalEntry,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addLearningJournalEntry(
    learningJournalId: number,
    body: LearningJournalEntry,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (learningJournalId === null || learningJournalId === undefined) {
      throw new Error('Required parameter learningJournalId was null or undefined when calling addLearningJournalEntry.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addLearningJournalEntry.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/learning-journals/${encodeURIComponent(String(learningJournalId))}/learning-journal-entries`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Deletes a learning journal entry
   * @param learningJournalId Learing Journal Identifier
   * @param learningJournalEntryId Learing Journal Entry Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (learningJournalId === null || learningJournalId === undefined) {
      throw new Error('Required parameter learningJournalId was null or undefined when calling deleteLearningJournalEntry.');
    }
    if (learningJournalEntryId === null || learningJournalEntryId === undefined) {
      throw new Error('Required parameter learningJournalEntryId was null or undefined when calling deleteLearningJournalEntry.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(
      `${this.basePath}/learning-journals/${encodeURIComponent(
        String(learningJournalId)
      )}/learning-journal-entries/${encodeURIComponent(String(learningJournalEntryId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a list of learning journal entries for a selected learning journal
   * @param learningJournalId Learing Journal Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLearningJournalEntries(
    learningJournalId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<LearningJournalEntry>>;
  public getLearningJournalEntries(
    learningJournalId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<LearningJournalEntry>>>;
  public getLearningJournalEntries(
    learningJournalId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<LearningJournalEntry>>>;
  public getLearningJournalEntries(
    learningJournalId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (learningJournalId === null || learningJournalId === undefined) {
      throw new Error('Required parameter learningJournalId was null or undefined when calling getLearningJournalEntries.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<LearningJournalEntry>>(
      `${this.basePath}/learning-journals/${encodeURIComponent(String(learningJournalId))}/learning-journal-entries`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a learning journal entry by id
   * @param learningJournalId Learing Journal Identifier
   * @param learningJournalEntryId Learing Journal Entry Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LearningJournalEntry>;
  public getLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LearningJournalEntry>>;
  public getLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LearningJournalEntry>>;
  public getLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (learningJournalId === null || learningJournalId === undefined) {
      throw new Error('Required parameter learningJournalId was null or undefined when calling getLearningJournalEntry.');
    }
    if (learningJournalEntryId === null || learningJournalEntryId === undefined) {
      throw new Error('Required parameter learningJournalEntryId was null or undefined when calling getLearningJournalEntry.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<LearningJournalEntry>(
      `${this.basePath}/learning-journals/${encodeURIComponent(
        String(learningJournalId)
      )}/learning-journal-entries/${encodeURIComponent(String(learningJournalEntryId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Updates an existing learning journal
   * @param learningJournalId Learing Journal Identifier
   * @param learningJournalEntryId Learing Journal Entry Identifier
   * @param body Learning journal entry object that is to be updated
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    body: LearningJournalEntry,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    body: LearningJournalEntry,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    body: LearningJournalEntry,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateLearningJournalEntry(
    learningJournalId: number,
    learningJournalEntryId: number,
    body: LearningJournalEntry,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (learningJournalId === null || learningJournalId === undefined) {
      throw new Error('Required parameter learningJournalId was null or undefined when calling updateLearningJournalEntry.');
    }
    if (learningJournalEntryId === null || learningJournalEntryId === undefined) {
      throw new Error('Required parameter learningJournalEntryId was null or undefined when calling updateLearningJournalEntry.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateLearningJournalEntry.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/learning-journals/${encodeURIComponent(
        String(learningJournalId)
      )}/learning-journal-entries/${encodeURIComponent(String(learningJournalEntryId))}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
