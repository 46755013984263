<div class="clearfix">
  <h2>Blog</h2>
  <div *ngFor="let blogEntry of blogEntries" class="my-3 clearfix">
    <h3>
      <a [routerLink]="['/blog', blogEntry.jobInterviewQuestionListId, seoFriendlyName(blogEntry.name)]">
        {{ blogEntry.name }}
      </a>
    </h3>
    <p>{{ blogEntry.description }}</p>
    <a
      [routerLink]="['/blog', blogEntry.jobInterviewQuestionListId, seoFriendlyName(blogEntry.name)]"
      class="btn btn-primary btn-sm float-right"
    >
      Read article
    </a>
  </div>
</div>
<div class="clearfix"></div>
