import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject, Optional } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    @Optional()
    @Inject(REQUEST)
    private request: Request
  ) {}

  setPageTitle(title: string, setOgTitleToSameValue: boolean = true): MetaDataService {
    let titleWithBrand = title;
    if (titleWithBrand.indexOf('BetterCoder.io') < 0) {
      if (titleWithBrand.length > 0) titleWithBrand += ' - ';
      titleWithBrand += 'BetterCoder.io';
    }

    this.titleService.setTitle(titleWithBrand);

    if (setOgTitleToSameValue) {
      this.addOpenGraphTitle(title);
    }

    return this;
  }

  addMetaTags(tags: MetaDefinition[]): MetaDataService {
    this.metaService.addTags(tags);
    return this;
  }

  addMetaTagDescription(value: string): MetaDataService {
    let tag: MetaDefinition = {
      name: 'description',
      content: value,
    };
    this.metaService.addTag(tag);

    return this;
  }

  addMetaTagRobots(value: string): MetaDataService {
    let tag: MetaDefinition = {
      name: 'robots',
      content: value,
    };
    this.metaService.addTag(tag);

    return this;
  }

  addOpenGraphType(value: string) {
    return this.addOpenGraphProperty('og:type', value);
  }

  addOpenGraphSiteName(value: string) {
    return this.addOpenGraphProperty('og:site_name', value);
  }

  addOpenGraphTitle(value: string) {
    return this.addOpenGraphProperty('og:title', value);
  }

  addOpenGraphDescription(value: string) {
    return this.addOpenGraphProperty('og:description', value);
  }

  addOpenGraphUrl(value: string) {
    return this.addOpenGraphProperty('og:url', value);
  }

  addOpenGraphImage(value: string) {
    return this.addOpenGraphProperty('og:image', value);
  }

  addOpenGraphImageAlt(value: string) {
    return this.addOpenGraphProperty('og:image:alt', value);
  }

  addDefaultMetaData(): MetaDataService {
    this.resetDefaultMetaData();

    const title = 'BetterCoder.io';
    this.setPageTitle(title);
    this.addOpenGraphSiteName('BetterCoder.io');
    this.addOpenGraphTitle(title);

    const isBrowser = isPlatformBrowser(this.platformId);

    const protocol = isBrowser ? location.protocol : this.request.protocol;
    const host = isBrowser ? location.host : this.request.get('host');
    const domain = protocol + '://' + host;

    let url = domain + this.location.path();
    let fullUrl = isBrowser ? this.document.location.href : domain + this.request.originalUrl;

    // TODO: Force the correct url. X-Forwarded-For doesn't seem to be as expected, troubleshoot later.
    url = url.replace('http://', 'https://');
    url = url.replace('bettercoder-ui:4000', 'www.bettercoder.io');

    fullUrl = fullUrl.replace('http://', 'https://');
    fullUrl = fullUrl.replace('bettercoder-ui:4000', 'www.bettercoder.io');

    const canonicalUrl = this.convertUrlToCanonicalUrl(fullUrl);
    this.setCanonicalUrl(canonicalUrl);

    this.addOpenGraphProperty('og:url', url);

    // Auxiliary routes won't be indexed (no-answer: ..., chat: ...)
    if (fullUrl.indexOf('(') > 0) {
      this.addMetaTagRobots('noindex');
    }

    return this;
  }

  setCanonicalUrl(url?: string) {
    const canURL = url == undefined ? this.document.URL : url;
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  private addOpenGraphProperty(property: string, content: string): MetaDataService {
    let selector = "property='" + property + "'";
    var openGraphTag = this.metaService.getTag(selector);

    let tagValue = {
      property: property,
      content: content,
    };

    if (openGraphTag == null) {
      this.metaService.addTag(tagValue);
    } else {
      this.metaService.updateTag(tagValue, selector);
    }

    return this;
  }

  private resetDefaultMetaData() {
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='robots'");

    let tags = ['og:site_name', 'og:title', 'og:description', 'og:type', 'og:url', 'og:image', 'og:image:alt'];

    tags.forEach((property) => this.removeOpenGraphPropertyIfNotExists(property));
  }

  private removeOpenGraphPropertyIfNotExists(property: string) {
    this.metaService.removeTag("property='" + property + "'");
  }

  private convertUrlToCanonicalUrl(url: string): string | null {
    let newUrl = this.stripAuxiliaryRoute(url);
    newUrl = this.stripQueryString(newUrl);
    newUrl = this.stripHashFragments(newUrl);
    return newUrl;
  }

  private stripAuxiliaryRoute(url: string): string | null {
    return url.split('(')[0].replace(/\/$/, '');
  }

  private stripQueryString(url: string): string | null {
    return url.split('?')[0];
  }

  private stripHashFragments(url: string): string | null {
    return url.split('#')[0];
  }
}
