import { Component, AfterViewInit, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LinksService } from 'src/app/api';
import { NotificationService } from '../../../notification/Notification.service';
import { IClipboardResponse } from 'ngx-clipboard';

@Component({
  selector: 'app-job-interview-question-challenge-dialog',
  templateUrl: './job-interview-question-challenge-dialog.component.html',
  styleUrls: ['./job-interview-question-challenge-dialog.component.scss'],
})
export class JobInterviewQuestionChallengeDialogComponent implements OnInit, AfterViewInit {
  public form: UntypedFormGroup;
  public isSaving: boolean;
  public isLoading: boolean;
  public jobInterviewQuestionId: number;

  @ViewChild('content', { static: true }) content: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private linksService: LinksService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.createForm();
    this.loadData();
  }

  createForm() {
    this.form = this.formBuilder.group({
      link: ['', Validators.required],
    });
  }

  loadData() {
    this.isLoading = true;
    let body = {};
    this.linksService
      .createJobInterviewQuestionChallengeLink(this.jobInterviewQuestionId, body, 'response')
      .subscribe(
        (value) => {
          this.form.patchValue({ link: value.body.url });
        },
        (error) => console.error(JSON.stringify(error)),
        () => {}
      )
      .add(() => {
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    this.open();
  }

  @HostListener('window:bettercoder_jobInterviewQuestionChallengeDialog_open')
  open() {
    this.modalService
      .open(this.content, {
        ariaLabelledBy: 'Challenge a friend',
        windowClass: 'full',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  onSubmit() {}

  copied(e: IClipboardResponse) {
    this.notificationService.success('The link was copied to your clipboard.');
  }
}
