import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safePipe'})
export class safePipe implements PipeTransform  {

  constructor(protected sanitizer: DomSanitizer) {}

  transform(value) {
    return (value == null) 
    ? null
    : this.sanitizer.bypassSecurityTrustHtml(value);
  }
}