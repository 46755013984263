import { IGamepadState } from './IGamepadState';

const standardControllerMappingsR: any = {
  "a0positive": "rStickTop",
  "a0negative": "rStickBottom",
  "a1positive": "rStickRight",
  "a1negative": "rStickLeft",
  "b0": "rA",
  "b1": "rX",
  "b2": "rB",
  "b3": "rY",
  "b4": "rSL",
  "b5": "rSR",
  "b7": "r2",
  "b8": "r1",
  "b9": "rSelect",
  "b16": "rHome",
  "b10": "rStickThumb",
}

const standardControllerMappingsLR: any = {
  "a0positive": "lStickRight",
  "a0negative": "lStickLeft",
  "a1positive": "lStickBottom",
  "a1negative": "lStickTop",
  "a2positive": "rStickRight",
  "a2negative": "rStickLeft",
  "a3positive": "rStickBottom",
  "a3negative": "rStickTop",
  "b0": "rB",
  "b1": "rA",
  "b2": "rY",
  "b3": "rX",
  "b4": "l1",
  "b5": "r1",
  "b6": "l2",
  "b7": "r2",
  "b8": "lCancel",
  "b9": "rSelect",
  "b11": "rStickThumb",
  "b12": "lArrowUp",
  "b13": "lArrowDown",
  "b14": "lArrowLeft",
  "b15": "lArrowRight",
  "b16": "rHome",
  "b17": "lBack",
  "b18": "lSL",
  "b19": "lSR",
  "b20": "rSL",
  "b21": "rSR",
}

const mobileControllerMappingsR: any = {
  // r2 not available
  // rB not available
  "b0": "rA",
  "b1": "rX",
  "b2": "rY",
  "b3": "rSL",
  "b7": "rSelect",
  "b9": "rStickThumb",
  "b11": "r1",
  "b12": "rStickLeft",
  "b13": "rStickRight",
  "b14": "rStickBottom",
  "b15": "rStickTop",
  "b16": "rHome",
}

const mobileControllerMappingsL: any = {
  // l2 not available
  // lSL not available
  // lArrowTop not available
  "b0": "lArrowLeft",
  "b1": "lArrowBottom",
  "b2": "lArrowRight",
  "b3": "lSL",
  "b6": "lCancel",
  "b8": "lStickThumb",
  "b10": "lBack",
  "b11": "l1",
  "b12": "lStickRight",
  "b13": "lStickLeft",
  "b14": "lStickTop",
  "b15": "lStickBottom",
}


export class GamepadMapper {
  GetDetectedMapping(gamePad: Gamepad) {

    if (gamePad.mapping == "standard") {
      if (gamePad.axes.length == 2) {
        return standardControllerMappingsR;
      }
      else if (gamePad.axes.length == 4) {
        return standardControllerMappingsLR;
      }
    }
    else if (gamePad.mapping == "") {
      if (gamePad.id == "Joy-Con (R)") {
        return mobileControllerMappingsR;
      }
      else if (gamePad.id == "Joy-Con (L)") {
        return mobileControllerMappingsL;
      }
    }
  }

  GetState(gamePad: Gamepad): IGamepadState {
    var currentMapping = this.GetDetectedMapping(gamePad);

    let gamepadState = <IGamepadState>{};

    for (let buttonIndex = 0; buttonIndex < gamePad.buttons.length; buttonIndex++) {
      let key = "b" + buttonIndex;
      let targetPropertyName = currentMapping[key];
      gamepadState[targetPropertyName] = gamePad.buttons[buttonIndex].value;
    }

    for (let axisIndex = 0; axisIndex < gamePad.axes.length; axisIndex++) {
      let axisValue = gamePad.axes[axisIndex];
      if (axisValue != 0) {
        let key = "a" + axisIndex;
        if (axisValue > 0) {
          key += "positive";
        }
        else if (axisValue < 0) {
          key += "negative";
        }

        let targetPropertyName = currentMapping[key];
        gamepadState[targetPropertyName] = Math.abs(axisValue);
      }
    }

    return gamepadState;
  }
}