<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <i class="fa fa fa-graduation-cap"></i>
      Challenge a friend
    </h4>
    <button
      type="button"
      class="btn btn-warning mt-1"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      Close
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!isLoading">
      <form method="post" #ngForm="ngForm" (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-group">
          <p>
            To challenge your friend or colleague, send him/her the link below. Anyone who has the link can join your challenge by
            visiting the URL and following the instructions.
          </p>
        </div>
        <div class="form-group">
          <label for="job-interview-question-challenge-link">Link</label>
          <input
            type="text"
            id="job-interview-question-challenge-link"
            class="form-control"
            formControlName="link"
            readonly="readonly"
            #inputTarget
          />
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button [ngxClipboard]="inputTarget" (cbOnSuccess)="copied($event)" class="btn btn-primary px-4">
              Copy to clipboard
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</ng-template>
