import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  isCollapsed: boolean = true;
  isIndexActive: boolean = false;
  isTestsActive: boolean = false;
  showFirstBanner: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e) => { 
      this.isCollapsed = true;

      let navigationEvent = <NavigationEnd>e;
      let url = navigationEvent.url;

      this.isIndexActive = url == '' || url == '/';
      this.isTestsActive = (url.indexOf('/tests') == 0 || url.indexOf('/test-runs') == 0);
      this.showFirstBanner = new Date().valueOf() % 2 == 0;
    });
  }
}