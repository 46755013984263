import { getLocaleDateTimeFormat, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
  }

  log(message?: any, ...optionalParams: any[]): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log(message, optionalParams);
    }
  }

  error(message?: any, ...optionalParams: any[]): void {
    if (isPlatformBrowser(this.platformId)) {
      console.error(message, optionalParams);
    }
    else {
      let d = new Date();
      let dateString = "[" + d.toLocaleString() + "] ";

      if (message instanceof HttpErrorResponse) {
        let m : HttpErrorResponse = message;
        let errorMessage = 
        "URL: " + m.url + 
        ", Status: " + m.status + 
        "; StatusText: " + m.statusText + 
        "; Message: " + m.message + 
        "; Error: ";

        for (var key in m.error) {
          if (m.error.hasOwnProperty(key)) {
              errorMessage += key + "=" + m.error[key] + "\n";
          }
      }
      
      errorMessage +=
        "; Heades: " + JSON.stringify(m.headers) + 
        "; Name: " + m.name +
        "; Ok: " + m.ok +
        "; Type: " + m.type;
          
        console.error(dateString + errorMessage);
      }
      else {
        console.trace(dateString + "error");
      }
    }
  }
}