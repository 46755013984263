/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { TestRun } from '../model/testRun';
import { TestRunAnswer } from '../model/testRunAnswer';
import { TestRunCreateRequest } from '../model/testRunCreateRequest';
import { TestRunIsCertificatePublicResult } from '../model/testRunIsCertificatePublicResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class TestRunsService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Creates a new test run
   * @param body TestRun object that needs to be added
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addTestRun(body: TestRunCreateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public addTestRun(body: TestRunCreateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public addTestRun(body: TestRunCreateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public addTestRun(body: TestRunCreateRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addTestRun.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/test-runs`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Creates a new test run answer
   * @param testRunUniqueIdentifier Test Run Identifier
   * @param body TestRun answer object that needs to be added
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addTestRunAnswer(
    testRunUniqueIdentifier: string,
    body: TestRunAnswer,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public addTestRunAnswer(
    testRunUniqueIdentifier: string,
    body: TestRunAnswer,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public addTestRunAnswer(
    testRunUniqueIdentifier: string,
    body: TestRunAnswer,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public addTestRunAnswer(
    testRunUniqueIdentifier: string,
    body: TestRunAnswer,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error('Required parameter testRunUniqueIdentifier was null or undefined when calling addTestRunAnswer.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addTestRunAnswer.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/test-runs/${encodeURIComponent(String(testRunUniqueIdentifier))}/answers`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Gets quiz test runs
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuizTestRuns(observe?: 'body', reportProgress?: boolean): Observable<Array<TestRun>>;
  public getQuizTestRuns(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TestRun>>>;
  public getQuizTestRuns(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TestRun>>>;
  public getQuizTestRuns(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<TestRun>>(`${this.basePath}/test-runs/quiz`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Returns a test run by id
   * @param testRunUniqueIdentifier Test Run Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTestRun(testRunUniqueIdentifier: string, observe?: 'body', reportProgress?: boolean): Observable<TestRun>;
  public getTestRun(
    testRunUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TestRun>>;
  public getTestRun(
    testRunUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TestRun>>;
  public getTestRun(testRunUniqueIdentifier: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error('Required parameter testRunUniqueIdentifier was null or undefined when calling getTestRun.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<TestRun>(`${this.basePath}/test-runs/${encodeURIComponent(String(testRunUniqueIdentifier))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Returns a test run question by its number
   * @param testRunUniqueIdentifier Test Run Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTestRunCertificate(testRunUniqueIdentifier: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
  public getTestRunCertificate(
    testRunUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public getTestRunCertificate(
    testRunUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public getTestRunCertificate(
    testRunUniqueIdentifier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error('Required parameter testRunUniqueIdentifier was null or undefined when calling getTestRunCertificate.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/octet-stream'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get(
      `${this.basePath}/test-runs/${encodeURIComponent(String(testRunUniqueIdentifier))}/certificate/pdf`,
      {
        responseType: 'blob',
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a test run question by its number
   * @param questionNumber Question number
   * @param testRunUniqueIdentifier Test Run Identifier
   * @param previousQuestionNumber Previous question number from which the user was navigating
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTestRunQuestionByNumber(
    questionNumber: number,
    testRunUniqueIdentifier: string,
    previousQuestionNumber?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TestRun>;
  public getTestRunQuestionByNumber(
    questionNumber: number,
    testRunUniqueIdentifier: string,
    previousQuestionNumber?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TestRun>>;
  public getTestRunQuestionByNumber(
    questionNumber: number,
    testRunUniqueIdentifier: string,
    previousQuestionNumber?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TestRun>>;
  public getTestRunQuestionByNumber(
    questionNumber: number,
    testRunUniqueIdentifier: string,
    previousQuestionNumber?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (questionNumber === null || questionNumber === undefined) {
      throw new Error('Required parameter questionNumber was null or undefined when calling getTestRunQuestionByNumber.');
    }
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter testRunUniqueIdentifier was null or undefined when calling getTestRunQuestionByNumber.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (previousQuestionNumber !== undefined) {
      queryParameters = queryParameters.set('previousQuestionNumber', <any>previousQuestionNumber);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<TestRun>(
      `${this.basePath}/test-runs/${encodeURIComponent(String(testRunUniqueIdentifier))}/questions/${encodeURIComponent(
        String(questionNumber)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a test run result
   * @param testRunUniqueIdentifier Test Run Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTestRunResult(testRunUniqueIdentifier: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getTestRunResult(
    testRunUniqueIdentifier: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public getTestRunResult(
    testRunUniqueIdentifier: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public getTestRunResult(
    testRunUniqueIdentifier: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error('Required parameter testRunUniqueIdentifier was null or undefined when calling getTestRunResult.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<any>(`${this.basePath}/test-runs/${encodeURIComponent(String(testRunUniqueIdentifier))}/result`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Returns a test run by id
   * @param testRunUniqueIdentifier Test Run Identifier
   * @param questionNumber Test Run Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTestRunWithQuestionByNumber(
    testRunUniqueIdentifier: string,
    questionNumber: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TestRun>;
  public getTestRunWithQuestionByNumber(
    testRunUniqueIdentifier: string,
    questionNumber: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TestRun>>;
  public getTestRunWithQuestionByNumber(
    testRunUniqueIdentifier: string,
    questionNumber: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TestRun>>;
  public getTestRunWithQuestionByNumber(
    testRunUniqueIdentifier: string,
    questionNumber: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter testRunUniqueIdentifier was null or undefined when calling getTestRunWithQuestionByNumber.'
      );
    }
    if (questionNumber === null || questionNumber === undefined) {
      throw new Error('Required parameter questionNumber was null or undefined when calling getTestRunWithQuestionByNumber.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<TestRun>(
      `${this.basePath}/test-runs/${encodeURIComponent(String(testRunUniqueIdentifier))}/${encodeURIComponent(
        String(questionNumber)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a test run question by its number
   * @param testRunUniqueIdentifier Test Run Identifier
   * @param isCertificatePublic Defines if the certificate should be updated to be public/private
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTestRunIsCertificatePublic(
    testRunUniqueIdentifier: string,
    isCertificatePublic: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TestRunIsCertificatePublicResult>;
  public updateTestRunIsCertificatePublic(
    testRunUniqueIdentifier: string,
    isCertificatePublic: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TestRunIsCertificatePublicResult>>;
  public updateTestRunIsCertificatePublic(
    testRunUniqueIdentifier: string,
    isCertificatePublic: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TestRunIsCertificatePublicResult>>;
  public updateTestRunIsCertificatePublic(
    testRunUniqueIdentifier: string,
    isCertificatePublic: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (testRunUniqueIdentifier === null || testRunUniqueIdentifier === undefined) {
      throw new Error(
        'Required parameter testRunUniqueIdentifier was null or undefined when calling updateTestRunIsCertificatePublic.'
      );
    }
    if (isCertificatePublic === null || isCertificatePublic === undefined) {
      throw new Error(
        'Required parameter isCertificatePublic was null or undefined when calling updateTestRunIsCertificatePublic.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (isCertificatePublic !== undefined) {
      queryParameters = queryParameters.set('isCertificatePublic', <any>isCertificatePublic);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.post<TestRunIsCertificatePublicResult>(
      `${this.basePath}/test-runs/${encodeURIComponent(String(testRunUniqueIdentifier))}/certificate/public`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
