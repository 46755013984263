import {
  Component,
  Inject,
  HostListener,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  PLATFORM_ID,
  OnInit,
  AfterViewChecked,
} from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError, ResolveEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { Location, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { JobInterviewQuestionChallengeDialogComponent } from './modules/core/components/job-interview-question-challenge-dialog/job-interview-question-challenge-dialog.component';
import { MetaDataService } from './modules/core/services/metadata/metadata.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'betterCoderApp';
  showHeaderAndFooter: boolean;
  isEmbedded: boolean;
  sub: any;
  layout: string;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(
    @Inject(DOCUMENT) private document,
    private location: Location,
    private app: ApplicationRef,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaDataService: MetaDataService,
    //    private oidcSecurityService: OidcSecurityService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isEmbedded = (environment.mode || '') === 'embedded';

    // this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
    //   console.log("check auth: isAuthenticated: " + isAuthenticated + ", idToken: " + idToken)
    //   if (isAuthenticated) {
    //     console.log("Is authenticated");
    //   }
    // });
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).forEach((event) => {
      let urlAfterRedirects = (<NavigationEnd>event).urlAfterRedirects;
      console.log('NavigationEnd Start' + urlAfterRedirects);
      if (environment.production && isPlatformBrowser(this.platformId) && typeof gtag !== 'undefined') {
        gtag('config', 'GTM-P2TN9JM', {
          page_path: urlAfterRedirects,
        });
      }

      this.layout = activatedRoute.root.firstChild.snapshot.data['layout'];
      var bodyContent = document.getElementById('body-content');
      if (bodyContent != null) {
        bodyContent.className = this.layout !== 'wide' ? 'container body-content' : '';
      }

      this.metaDataService.addDefaultMetaData();
      console.log('NavigationEnd End' + urlAfterRedirects);
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationError)).forEach((event) => {
      console.log(`NAV ERROR -> ${event}`);
    });
  }

  ngOnInit() {
    this.handleScrollToTopOnRoute();
  }

  ngAfterViewChecked() {}

  handleScrollToTopOnRoute() {
    this.location?.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = (<any>ev).url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl && isPlatformBrowser(this.platformId)) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (ev instanceof NavigationEnd) {
        let x, y;
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          x = 0;
          y = this.yScrollStack.pop();
        } else {
          x = 0;
          y = 0;
        }
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(x, y);
        }
      }
    });
  }
  @ViewChild('componentPlaceholder', { read: ViewContainerRef })
  public componentPlaceholder: ViewContainerRef;

  @HostListener('window:bettercoder_navigate_to_route', ['$event'])
  navigateToRoute(event: any) {
    var commands = event.detail.commands;
    this.router.navigate(commands);
  }

  @HostListener('window:bettercoder_lazy_load_component', ['$event'])
  lazyLoadComponent(event: any) {
    const map = {
      JobInterviewQuestionChallengeDialogComponent: JobInterviewQuestionChallengeDialogComponent,
    };

    var placeholderId = event.detail.placeholderId;
    var element = document.getElementById(placeholderId);
    var component = map[event.detail.componentName];
    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef = factory.create(this.injector, [], element);

    var properties = event.detail.properties;
    if (properties) {
      for (let propertyName in properties) {
        componentRef.instance[propertyName] = properties[propertyName];
      }
    }
    componentRef.componentType;
    this.app.attachView(componentRef.hostView);
  }
}
