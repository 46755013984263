import { NgModule } from '@angular/core';
import { NotificationService } from './Notification.service';
import { NotificationComponent } from './toastr-notification/toastr-notification.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule],
  exports: [NotificationComponent],
  providers: [NotificationService],
})
export class NotificationModule {}
