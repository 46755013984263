import { Component, OnInit } from '@angular/core';
import { SignInService } from '@api/modules/core/services/sign-in/sign-in.service';

@Component({
  selector: 'app-sign-in-button',
  templateUrl: './sign-in-button.component.html',
  styleUrls: ['./sign-in-button.component.scss']
})
export class SignInButtonComponent implements OnInit {
  constructor(private signInService: SignInService) {  }

  
  ngOnInit() {
  }

  openSignInDialog() {
    this.signInService.raiseSignInFormOpenEvent();
  }
}