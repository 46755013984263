import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/modules/core/services/current-user.service';
import { UserProfileEditComponent } from 'src/app/modules/accounts/user-profile-edit/user-profile-edit.component';
import { LoggingService } from '@api/modules/core/services/logging/LoggingService';
import { AccountsService } from '@api/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-login-partial',
  templateUrl: './menu-login-partial.component.html',
  styleUrls: ['./menu-login-partial.component.scss'],
})
export class MenuLoginPartialComponent implements OnInit {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private accountsService: AccountsService,
    private loggingService: LoggingService
  ) {}
  public isAuthenticated: boolean = false;

  ngOnInit() {
    this.currentUserService.isAuthenticated$.subscribe(
      (value) => {
        this.isAuthenticated = value;
      },
      (error) => this.loggingService.error(error)
    );
  }

  signOut() {
    this.accountsService.signOut().subscribe(() => {
      this.currentUserService.setIsAuthenticated(false);
      this.router.navigate(['/accounts/signed-out']);
    });
  }
}
