import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private notificationSound: HTMLAudioElement = null;
  private clickSound: HTMLAudioElement = null;
  private correctSound: HTMLAudioElement = null;
  private incorrectSound: HTMLAudioElement = null;

  private audios: Array<HTMLAudioElement> = [];

  constructor() { }

  releaseExistingAudios() {
    this.audios.forEach((audio) => {
      if (!audio.paused || audio.currentTime) {
        audio.pause();
        audio.removeAttribute('src');
        audio.load();
      }
    });
  }

  playNotification() {
    if (this.notificationSound == null) {
      this.notificationSound = new Audio('/assets/sound/click.mp3');
    }

    this.notificationSound.play();
  }

  playClick() {
    if (this.clickSound != null) {
      this.clickSound.play();
    }
  }

  playCorrect() {
    if (this.correctSound != null) {
      this.correctSound.play();
    }
  }

  playIncorrect() {
    if (this.incorrectSound != null) {
      this.incorrectSound.play();
    }
  }

  thenPlay(playAfterElement: HTMLAudioElement, url: string) {
    if (this.clickSound == null) {
      this.clickSound = new Audio('/assets/sound/click.mp3');
      this.correctSound = new Audio('/assets/sound/correct.mp3');
      this.incorrectSound = new Audio('/assets/sound/incorrect.mp3');
    }

    let audio = new Audio();
    audio.src = url;
    audio.load();
    this.audios.push(audio);

    if (playAfterElement != null) {
      fromEvent(playAfterElement, 'ended').subscribe(() => {
        audio.play();
      });
    }

    return audio;
  }

}
