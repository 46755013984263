import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SignInService } from '@api/modules/core/services/sign-in/sign-in.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
})
export class SignInDialogComponent implements OnInit {
  @ViewChild('content', { static: true }) content: ElementRef;
  
  constructor(private signInService: SignInService,
    private modalService: NgbModal) {}
    
  ngOnInit() {
    this.signInService.getSignInFormOpenEvent().subscribe(() => {
      this.open(this.content);
    })
  }

  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'mySmallModalLabel'})
    .result.then((result) => {}, 
    (reason) => {
    });
  }
}