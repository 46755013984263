<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Sign In</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-sign-in-form></app-sign-in-form>
  </div>
</ng-template>
