import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor() { }

  seoFriendly(input: string)
  {
    if (input == null) return "";

    let output = "";
    input = input.toLowerCase();

    for (var i = 0; i < input.length; i++) {
      var c = input.charAt(i);
      if ((c >= 'a' && c <= 'z') ||
          (c >= '0' && c <= '9'))
      {
          output += c;
      }
      else if (
          c == '+' ||
          c == '-' ||
          c == ' ')
      {
        output += '-';
      }
    }

    return output;
  }
}