<ng-container *ngIf="!isEmbedded">
  <div class="main-content" id="main-content">
    <app-menu></app-menu>
    <!--Optional Section Top Banner here-->
    <router-outlet name="top-banner"></router-outlet>
    <div id="body-content">
      <div id="notification">
        <!--Message Component-->
      </div>
      <div class="outlet">
        <router-outlet></router-outlet>
        <router-outlet name="chat"></router-outlet>
        <router-outlet name="modal"></router-outlet>
        <router-outlet name="no-answer"></router-outlet>
      </div>
    </div>
  </div>

  <app-go-to-top></app-go-to-top>
  <app-footer></app-footer>
</ng-container>
<ng-container *ngIf="isEmbedded">
  <router-outlet></router-outlet>
  <router-outlet name="chat"></router-outlet>
  <router-outlet name="modal"></router-outlet>
  <router-outlet name="no-answer"></router-outlet>
</ng-container>
