/**
 * Better Coder API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { StudentProfile } from '../model/studentProfile';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class StudentProfilesService {
  protected basePath = 'http://localhost:10010/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Creates a new student profile
   * @param body Student profile object that needs to be added
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addStudentProfile(body: StudentProfile, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public addStudentProfile(body: StudentProfile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public addStudentProfile(body: StudentProfile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public addStudentProfile(body: StudentProfile, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addStudentProfile.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/student-profiles`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Deletes a student profile
   * @param studentProfileId Student Profile Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteStudentProfile(studentProfileId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteStudentProfile(
    studentProfileId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteStudentProfile(studentProfileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteStudentProfile(studentProfileId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (studentProfileId === null || studentProfileId === undefined) {
      throw new Error('Required parameter studentProfileId was null or undefined when calling deleteStudentProfile.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(`${this.basePath}/student-profiles/${encodeURIComponent(String(studentProfileId))}`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Returns a student profile of the currently signed in user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCurrentStudentProfile(observe?: 'body', reportProgress?: boolean): Observable<StudentProfile>;
  public getCurrentStudentProfile(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StudentProfile>>;
  public getCurrentStudentProfile(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StudentProfile>>;
  public getCurrentStudentProfile(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<StudentProfile>(`${this.basePath}/student-profile`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Returns a student profile by id
   * @param studentProfileId Student Profile Identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStudentProfile(studentProfileId: number, observe?: 'body', reportProgress?: boolean): Observable<StudentProfile>;
  public getStudentProfile(
    studentProfileId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<StudentProfile>>;
  public getStudentProfile(
    studentProfileId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<StudentProfile>>;
  public getStudentProfile(studentProfileId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (studentProfileId === null || studentProfileId === undefined) {
      throw new Error('Required parameter studentProfileId was null or undefined when calling getStudentProfile.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<StudentProfile>(
      `${this.basePath}/student-profiles/${encodeURIComponent(String(studentProfileId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Returns a list of student rofiles
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStudentProfiles(observe?: 'body', reportProgress?: boolean): Observable<Array<StudentProfile>>;
  public getStudentProfiles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StudentProfile>>>;
  public getStudentProfiles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StudentProfile>>>;
  public getStudentProfiles(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<StudentProfile>>(`${this.basePath}/student-profiles`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   * Updates an existing student profile
   * @param studentProfileId Student Profile Identifier
   * @param body Student profile object holding the updated data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateStudentProfile(
    studentProfileId: number,
    body: StudentProfile,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateStudentProfile(
    studentProfileId: number,
    body: StudentProfile,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateStudentProfile(
    studentProfileId: number,
    body: StudentProfile,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateStudentProfile(
    studentProfileId: number,
    body: StudentProfile,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (studentProfileId === null || studentProfileId === undefined) {
      throw new Error('Required parameter studentProfileId was null or undefined when calling updateStudentProfile.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateStudentProfile.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    let consumes: string[] = ['application/json'];
    let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/student-profiles/${encodeURIComponent(String(studentProfileId))}`, body, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
