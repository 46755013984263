import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BeforeInstallPromptEvent } from '../../components/install-pwa/BeforeInstallPromptEvent';

@Injectable({ providedIn: 'root' })
export class PwaService {
  public promptEvent: BeforeInstallPromptEvent;
  public log: string;
  public beforeinstallpromptHooked: boolean = false;
  public isInstalled: boolean = false;
  public userAccepted: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.log = 'PwaService initialized\n';

    // PWA service won't be hooking
    if (!isPlatformBrowser(this.platformId)) return;

    window.addEventListener('appinstalled', event => {
      this.isInstalled = true;
    });

    window.addEventListener('beforeinstallprompt', event => {
      this.beforeinstallpromptHooked = true;
      this.log += 'Started call beforeinstallprompt\n';
      this.log += event;

      this.promptEvent = <BeforeInstallPromptEvent>event;

      this.promptEvent.userChoice.then(choiceResult => {
        this.userAccepted = choiceResult.outcome === 'accepted';
      });

      this.log += 'Finished call beforeinstallprompt\n';
    });
  }
}
